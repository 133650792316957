import React, { memo } from 'react';
import { useSelector } from 'react-redux';

// import constants
import { LOADING_STATUS } from '../../../constants/loadingStatuses';

// import utils
import { _t } from '../../../utils/i18n';

// import components
import TotalAmountItem         from './TotalAmountItem';
import TotalAmountItemSkeleton from './TotalAmountItemSkeleton';

const ProductTypesTotalAmount = memo( () => {
  // get state
  const { status, productTypesAmountData } = useSelector( state => state.landing );

  // define skeleton
  const skeleton = Array.from({ length: 3 }, ( _, index ) => {
    return <TotalAmountItemSkeleton key={ index } />;
  });

  return (
    <div className="total-amount">
      <h4 className="total-amount__title">{ _t( 'product_total_amount_title' ) }</h4>
      { status === LOADING_STATUS ?
        skeleton
      : productTypesAmountData.map( item => <TotalAmountItem key={ item.id } data={ item } /> ) }
    </div>
  )
});

export default ProductTypesTotalAmount;