import React, { memo } from 'react';

// import components
import SkeletonLine from '../../common/Skeleton/SkeletonLine';

const TableSkeleton = memo( ( { colCount } ) => {
  const headerRow = Array.from({ length: colCount }, ( _, index ) => {
    return (
      <div className="table__skeleton-item__table__head__cell" key={ index }>
        <SkeletonLine className="table__skeleton-item__table__head__cell__line" />
      </div>
    );
  });

  const row = Array.from({ length: colCount }, ( _, index ) => {
    return (
      <div className="table__skeleton-item__table__row__cell" key={ index }>
        <SkeletonLine className="table__skeleton-item__table__row__cell__line" />
      </div>
    );
  });

  return (
    <div className="table__skeleton-item">
      <div className="table__skeleton-item__actions">
        {/*<SkeletonLine className="table__skeleton-item__actions__line"/>*/}
      </div>
      <div className="table__skeleton-item__filter">
        <SkeletonLine className="table__skeleton-item__filter__line"/>
      </div>
      <div className="table__skeleton-item__table">
        <div className="table__skeleton-item__table__head">{ headerRow }</div>
        <div className="table__skeleton-item__table__row">{ row }</div>
        <div className="table__skeleton-item__table__row">{ row }</div>
        <div className="table__skeleton-item__table__row">{ row }</div>
        <div className="table__skeleton-item__table__row">{ row }</div>
        <div className="table__skeleton-item__table__row">{ row }</div>
      </div>
      <div className="table__skeleton-item__footer">
        <SkeletonLine className="table__skeleton-item__footer__line"/>
      </div>
    </div>
  )
});

export default TableSkeleton;