import { createSlice } from '@reduxjs/toolkit';

const LSVFormStepsSlice = createSlice({
  name: 'lsvForm',
  initialState: {
    firstName: '',
    lastName: '',
    street: '',
    city: '',
    iban: ''
  },
  reducers: {
    setData: ( state, action ) => {
      const { firstName, lastName, street, city, iban } = action.payload;

      state.firstName = firstName;
      state.lastName  = lastName;
      state.street    = street;
      state.city      = city;
      state.iban      = iban;
    }
  }
});

export const { setData } = LSVFormStepsSlice.actions;

export default LSVFormStepsSlice.reducer;