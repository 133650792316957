import React, { memo } from 'react';

const PageTitle = memo( ( { title, description } ) => {
  return (
    <div className="page-title">
      <h1 className="page-title__text">{ title }</h1>
      { description ? <p className="page-title__description">{ description }</p> : false }
    </div>
  )
});

export default PageTitle;