import React, { memo } from 'react';

// import utils
import { _t } from '../../../utils/i18n';

const Badge = memo( ( { type = 'primary', text = '' } ) =>
  <span className={ `badge badge--${ type }` }>
    { type === 'primary' ? text === '' ? _t( 'crowdfunding_status_primary' ) : text : false }
    { type === 'warning' ? text === '' ? _t( 'crowdfunding_status_warning' ) : text  : false }
    { type === 'failed' ? text === '' ? _t( 'crowdfunding_status_failed' ) : text  : false }
    { type === 'success' ? text === '' ? _t( 'crowdfunding_status_success' ) : text  : false }
  </span> );

export default Badge;