import React, { memo } from 'react';

// import components
import SkeletonVerticalLine from '../../common/Skeleton/SkeletonVerticalLine';
import SkeletonLine         from '../../common/Skeleton/SkeletonLine';
import SkeletonXaxis        from '../../common/Skeleton/SkeletonXaxis';
import SkeletonYaxis        from '../../common/Skeleton/SkeletonYaxis';

const ChartBarStackedSkeleton = memo( () => {
  return (
    <div className="stacked-bar__skeleton-item">
      <div className="stacked-bar__skeleton-item__verticals">
        <SkeletonYaxis className="stacked-bar__skeleton-item__y-axis" />
        <SkeletonVerticalLine className="stacked-bar__skeleton-item__vertical-line stacked-bar__skeleton-item__vertical-line--type-1" />
        <SkeletonVerticalLine className="stacked-bar__skeleton-item__vertical-line stacked-bar__skeleton-item__vertical-line--type-2" />
        <SkeletonVerticalLine className="stacked-bar__skeleton-item__vertical-line stacked-bar__skeleton-item__vertical-line--type-3" />
        <SkeletonVerticalLine className="stacked-bar__skeleton-item__vertical-line stacked-bar__skeleton-item__vertical-line--type-1" />
        <SkeletonVerticalLine className="stacked-bar__skeleton-item__vertical-line stacked-bar__skeleton-item__vertical-line--type-2" />
        <SkeletonVerticalLine className="stacked-bar__skeleton-item__vertical-line stacked-bar__skeleton-item__vertical-line--type-3" />
        <SkeletonVerticalLine className="stacked-bar__skeleton-item__vertical-line stacked-bar__skeleton-item__vertical-line--type-1" />
        <SkeletonVerticalLine className="stacked-bar__skeleton-item__vertical-line stacked-bar__skeleton-item__vertical-line--type-2" />
        <SkeletonVerticalLine className="stacked-bar__skeleton-item__vertical-line stacked-bar__skeleton-item__vertical-line--type-3" />
        <SkeletonVerticalLine className="stacked-bar__skeleton-item__vertical-line stacked-bar__skeleton-item__vertical-line--type-1" />
        <SkeletonVerticalLine className="stacked-bar__skeleton-item__vertical-line stacked-bar__skeleton-item__vertical-line--type-2" />
        <SkeletonVerticalLine className="stacked-bar__skeleton-item__vertical-line stacked-bar__skeleton-item__vertical-line--type-3" />
      </div>

      <div className="stacked-bar__skeleton-item__horizontals">
        <SkeletonXaxis className="stacked-bar__skeleton-item__x-axis" />
      </div>

      <div className="stacked-bar__skeleton-item__legend">
        <SkeletonLine className="stacked-bar__skeleton-item__legend__line" />
        <SkeletonLine className="stacked-bar__skeleton-item__legend__line" />
        <SkeletonLine className="stacked-bar__skeleton-item__legend__line" />
        <SkeletonLine className="stacked-bar__skeleton-item__legend__line" />
      </div>
    </div>
  )
});

export default ChartBarStackedSkeleton;