// import utils
import { _t } from '../utils/i18n';

// import helpers
import { getPaymentTypeByKeyword } from './getPaymentTypeByKeyword';

/**
 * Replace the table keywords to the translatable text
 * Replace the following keywords: donation_purpose, donation_type, interval, active
 *
 * @param data | array
 * @return array
 */
export const replaceTableKeywordsToText = data => {
  return data.map( row => {
    const { donation_purpose, donation_type, interval, active } = row;

    return {
      ...row,
      donation_purpose: donation_purpose === 'no_purpose' ? _t( 'table_no_purpose' ) : donation_purpose,
      donation_type: getPaymentTypeByKeyword( donation_type ),
      interval: _t( 'table_interval_' + interval ),
      status: active ? _t( 'table_active' ) : _t( 'table_inactive' ),
    };
  });
};