import React, { memo } from 'react';

// import helper
import { swissNumberFormat } from '../../../helpers/swissNumberFormat';

// import utils
import { _t }                        from '../../../utils/i18n';
import { getUser, isUserRemembered } from '../../../utils/Auth';

// import components
import Badge from '../Badge/Badge';

const CrowdfundingProjectItem = memo( ( { data } ) => {
  const { name, date, donationsAmount, costsReceived, costsGoal, percent, statusType, deadline } = data;

  // get the user currency
  const isRemembered = isUserRemembered();
  const { currency } = getUser( isRemembered );

  return (
    <div className="crowdfunding-projects__table__row crowdfunding-project-item">
      <div className="crowdfunding-project-item__cell">
        <div className="crowdfunding-project-item__cell__name">
          <span className="crowdfunding-project-item__cell__name__top">{ name }</span>
          <span className="crowdfunding-project-item__cell__name__bottom">{ `${ _t( 'crowdfunding_project_published' ) }: ${ date }` }</span>
        </div>
      </div>
      <div className="crowdfunding-project-item__cell">
        <span className="crowdfunding-project-item__cell__digits">{ donationsAmount }</span>
      </div>

      <span className="crowdfunding-project-item__cell">
        { `${ currency } ${ swissNumberFormat( costsReceived ) }` }
      </span>

      <span className="crowdfunding-project-item__cell">
        { `${ currency } ${ swissNumberFormat( costsGoal ) }` }
      </span>

      <div className="crowdfunding-project-item__cell">
        <div className="crowdfunding-project-item__cell__progress">
          <div className="crowdfunding-project-item__cell__progress__bar">
            <span className={ `crowdfunding-project-item__cell__progress__fill crowdfunding-project-item__cell__progress__fill--${ statusType }` }
                  style={{ width: percent + '%' }} />
          </div>

          <div className="crowdfunding-project-item__cell__progress__percentage">
            <span className={ `crowdfunding-project-item__cell__progress__percentage__value crowdfunding-project-item__cell__progress__percentage__value--${ statusType }` }>{ `${ percent }%` }</span>
            <span className="crowdfunding-project-item__cell__progress__percentage__text">{ _t( 'crowdfunding_project_donated' ) }</span>
          </div>
        </div>
      </div>

      <div className="crowdfunding-project-item__cell">
        <Badge type={ statusType } />
      </div>

      <span className="crowdfunding-project-item__cell">{ deadline }</span>
    </div>
  )
});

export default CrowdfundingProjectItem;