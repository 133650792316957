import React, { memo } from 'react';

// import components
import SkeletonLine from '../../common/Skeleton/SkeletonLine';

const TopStatsItemSkeleton = memo( () => {
  return (
    <div className="top-stats__skeleton-item">
      <SkeletonLine className="top-stats__skeleton-item__top" />
      <div className="top-stats__skeleton-item__bottom">
        <div className="top-stats__skeleton-item__bottom__left">
          <SkeletonLine className="top-stats__skeleton-item__bottom__left__top" />
          <SkeletonLine className="top-stats__skeleton-item__bottom__left__bottom" />
        </div>
        <div className="top-stats__skeleton-item__bottom__right">
          <SkeletonLine className="top-stats__skeleton-item__bottom__right__line" />
        </div>
      </div>
    </div>
  )
});

export default TopStatsItemSkeleton;