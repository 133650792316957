import { configureStore } from '@reduxjs/toolkit'

// import reducers
import loginReducer                      from '../components/common/Login/store/loginSlice';
import sidebarReducer                    from '../components/common/Sidebar/store/sidebarSlice';
import calendarReducer                   from '../components/elements/Calendar/store/calendarSlice';
import toastReducer                      from '../components/elements/Toast/store/toastSlice';
import confirmationPopupReducer          from '../components/elements/Popup/store/popupConfirmationSlice';
import resendPopupReducer                from '../components/elements/Popup/store/popupResendSlice';
import tableReducer                      from '../components/elements/Table/store/tableSlice';
import lsvFormReducer                    from '../components/elements/LSVFormSteps/store/LSVFormStepsSlice';
import landingReducer                    from '../pages/Statistics/api/statisticsSlice.js';
import allPaymentsReducer                from '../pages/AllPayments/api/allPaymentsSlice';
import allDonationsReducer               from '../pages/AllDonations/api/allDonationsSlice';
import recurringDonationsReducer         from '../pages/RecurringDonations/api/recurringDonationsSlice';
import recurringDonationsIntervalReducer from '../pages/RecurringDonationsInterval/api/recurringDonationsIntervalSlice';
import singleDonationsReducer            from '../pages/SingleDonations/api/singleDonationsSlice';
import crowdfundingReducer               from '../pages/Crowdfunding/api/crowdfundingSlice';
import merchandiseReducer                from '../pages/Merchandise/api/merchandiseSlice';
import shopReducer                       from '../pages/Shop/api/shopSlice';
import allMethodsReducer                 from '../pages/AllMethods/api/allMethodsSlice';
import creditCardPaymentsReducer         from '../pages/CreditCardPayments/api/creditCardPaymentsSlice';
import ewalletPaymentsReducer            from '../pages/EwalletPayments/api/ewalletPaymentsSlice';
import twintPaymentsReducer              from '../pages/TwintPayments/api/twintSlice';
import postfinancePaymentsReducer        from '../pages/PostfinancePayments/api/postfinancePaymentsSlice';
import cryptoPaymentsReducer             from '../pages/CryptoPayments/api/cryptoPaymentsSlice';
import paypalPaymentsReducer             from '../pages/PaypalPayments/api/paypalPaymentsSlice';
import invoicePaymentsReducer            from '../pages/InvoicePayments/api/invoicePaymentsSlice';

export default configureStore({
  reducer: {
    login                      : loginReducer,
    sidebar                    : sidebarReducer,
    calendar                   : calendarReducer,
    toast                      : toastReducer,
    confirmationPopup          : confirmationPopupReducer,
    resendPopup                : resendPopupReducer,
    table                      : tableReducer,
    lsvForm                    : lsvFormReducer,
    landing                    : landingReducer,
    allPayments                : allPaymentsReducer,
    allDonations               : allDonationsReducer,
    recurringDonations         : recurringDonationsReducer,
    recurringDonationsInterval : recurringDonationsIntervalReducer,
    singleDonations            : singleDonationsReducer,
    crowdfunding               : crowdfundingReducer,
    merchandise                : merchandiseReducer,
    shop                       : shopReducer,
    allMethods                 : allMethodsReducer,
    creditCardPayments         : creditCardPaymentsReducer,
    ewalletPayments            : ewalletPaymentsReducer,
    twintPayments              : twintPaymentsReducer,
    postfinancePayments        : postfinancePaymentsReducer,
    cryptoPayments             : cryptoPaymentsReducer,
    paypalPayments             : paypalPaymentsReducer,
    invoicePayments            : invoicePaymentsReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware( { serializableCheck: false } ),
})