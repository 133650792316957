import React, { memo, useEffect, useState } from 'react';
import { useSelector }                      from 'react-redux';
import moment                               from 'moment';

// import constants
import {
  PERIOD_30_DAYS, PERIOD_YESTERDAY, PERIOD_DAY_BEFORE_YESTERDAY, PERIOD_LAST_WEEK, PERIOD_LAST_MONTH, PERIOD_LAST_YEAR,
  PERIOD_MONTH_BEFORE_THIS_MONTH, PERIOD_LAST_QUARTER, PERIOD_QUARTER_BEFORE_LAST_QUARTER, PERIOD_YEAR_BEFORE_LAST_YEAR
} from '../../../constants/calendarRanges';

// import utils
import { _t, momentLocale } from '../../../utils/i18n';

const TopStatsItemBottomText = memo( ( { dynamicPeriod } ) => {
  // get the global state
  const { period } = useSelector( state => state.calendar );
  const { dateFrom: stateDateFrom, dateTo: stateDateTo } = period;

  // define the component state
  const [isPeriodCustom, setIsPeriodCustom] = useState( false );
  const [dateFrom] = useState( stateDateFrom === '' ? moment().subtract( 29, 'days' ) : moment( new Date( stateDateFrom ) ) );
  const [dateTo]   = useState( stateDateTo === '' ? moment() : moment( new Date( stateDateTo ) ) );

  // check if the period is custom
  useEffect( () => {
    if (
      dynamicPeriod !== PERIOD_30_DAYS &&
      dynamicPeriod !== PERIOD_YESTERDAY &&
      dynamicPeriod !== PERIOD_DAY_BEFORE_YESTERDAY &&
      dynamicPeriod !== PERIOD_LAST_WEEK &&
      dynamicPeriod !== PERIOD_LAST_MONTH &&
      dynamicPeriod !== PERIOD_MONTH_BEFORE_THIS_MONTH &&
      dynamicPeriod !== PERIOD_LAST_QUARTER &&
      dynamicPeriod !== PERIOD_QUARTER_BEFORE_LAST_QUARTER &&
      dynamicPeriod !== PERIOD_LAST_YEAR &&
      dynamicPeriod !== PERIOD_YEAR_BEFORE_LAST_YEAR
    ) {
      setIsPeriodCustom( true );
    } else {
      setIsPeriodCustom( false );
    }
  }, [dynamicPeriod] );

  // TODO: add slide text
  // code example here https://codepen.io/Daryl_Ross/pen/ZEYwYaw
  const mouseEnterHandler = () => {
    //console.log( 'mouse enter' );
  };

  const mouseLeaveHandler = () => {
    //console.log( 'mouse leave' );
  };

  const periodAsText = isPeriodCustom ? `${ moment( dateFrom ).locale( momentLocale() ).format( 'DD.MM.YY' ) } - ${ moment( dateTo ).locale( momentLocale() ).format( 'DD.MM.YY' ) }` : _t( 'topstats_' + dynamicPeriod );
  const text         = _t( 'topstats_from' ) + ' ' + periodAsText;

  return (
    <span className="top-stats__item__text"
          title={ text }
          onMouseEnter={ () => mouseEnterHandler() }
          onMouseLeave={ () => mouseLeaveHandler() }>
      { text }
    </span>
  );
});

export default TopStatsItemBottomText;