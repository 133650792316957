import React, { memo, useEffect }   from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import actions
import { hideToast } from './store/toastSlice';

// import icons
import { IconX } from '@tabler/icons-react';

// import components
import ToastIconInfo    from './ToastIconInfo';
import ToastIconSuccess from './ToastIconSuccess';
import ToastIconWarning from './ToastIconWarning';

const Toast = memo( () => {
  // get the global state
  const { isShown, type, text } = useSelector( state => state.toast );

  // define dispatch
  const dispatch = useDispatch();

  // automatically hide toast
  useEffect( () => {
    if ( isShown && type !== 'danger' ) {
      setTimeout( () =>
        dispatch( hideToast( !isShown ) ), 5000 );
    }
  }, [isShown] );

  return (
    <div className={ isShown ? `toast toast--is-visible toast--${ type }` : `toast toast--${ type }` }>
      <div className="toast__content">
        <div className={ `toast__icon toast__icon--${ type }` }>
          { type === 'success' ? <ToastIconSuccess />: false }
          { type === 'info' ? <ToastIconInfo />: false }
          { type === 'warning' || type === 'danger' ? <ToastIconWarning />: false }
        </div>
        <p className="toast__text">{ text }</p>
      </div>
      <span className="toast__close"
            onClick={ () => dispatch( hideToast( !isShown ) ) }>
        <IconX className="toast__close__icon"
               color="#73879C"/>
      </span>
    </div>
  )
});

export default Toast;