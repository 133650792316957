// import utils
import { getUser, isUserRemembered } from '../utils/Auth';

/**
 * Convert numbers to Swiss format (1000 -> 1'000)
 *
 * @param number | int, float, string
 * @return string
 */
export const swissNumberFormat = number =>
  new Intl.NumberFormat( 'de-CH' ).format( number );

/**
 * Helper to return swiss number format in any representations
 *
 * @param value | string
 * @param currency | bool
 * @return string
 */
export const swissNumberInConfig = ( value, currency = false ) => {
  const isRemembered               = isUserRemembered();
  const { currency: userCurrency } = getUser( isRemembered );

  const isFloat = value % 1 !== 0;

  if ( currency ) {
    return isFloat ? userCurrency + ' ' + swissNumberFormat( value.toFixed( 2 ) ) : userCurrency + ' ' + swissNumberFormat( value );
  } else {
    return isFloat ? swissNumberFormat( value.toFixed( 2 ) ) : swissNumberFormat( value );
  }
};