import { createSlice } from '@reduxjs/toolkit'

// import helpers
import { getPeriod } from '../../../../api/helper';

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    period: {
      dateFrom: '',
      dateTo: '',
      period: ''
    },
    allTimeAllPayments: false,
  },
  reducers: {
    periodChanged: ( state, action ) => {
      const { dateFrom, dateTo, period } = action.payload;

      state.period = { dateFrom, dateTo, period: getPeriod( period ) };
    },
    changeAllTimeAllPayments: ( state, action ) => {
      state.allTimeAllPayments = action.payload;
    }
  }
});

export const { periodChanged, changeAllTimeAllPayments } = calendarSlice.actions;

export default calendarSlice.reducer;