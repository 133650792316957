import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart  from 'react-apexcharts';

// import constants
import { SUCCEEDED_STATUS } from '../../../constants/loadingStatuses';

// import utils
import { _t } from '../../../utils/i18n';

// import config
import { stackedBarConfig } from './config';

// import components
import ChartBarStackedSkeleton from './ChartBarStackedSkeleton';

const ChartBarStacked = memo( () => {
  // get the global state
  const { status, categories, series } = useSelector( store => store.recurringDonationsInterval );

  // define the chart options
  const options = stackedBarConfig( categories );

  // prepare series
  const updatedSeries = series.map( item => {
    const { name, data } = item;

    return {
      name: _t( 'recurring_' + name ),
      data
    }
  });



  /*useEffect( () => {
    if ( status === SUCCEEDED_STATUS ) {
      console.log( updatedSeries );
      updatedSeries.forEach( ( value, index, c ) => {
        console.log( index );

        if ( index === 1 ) {
          console.log( value.data );
        }
      });
    }
  }, [status] );*/



  return (
    <div className="top-products stacked-bar">
      { status === SUCCEEDED_STATUS ?
        <ReactApexChart options={ options }
                        series={ updatedSeries }
                        type="bar"
                        width="100%"
                        height="500" />
      : <ChartBarStackedSkeleton /> }
    </div>
  )
});

export default ChartBarStacked;