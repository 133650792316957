import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector }         from 'react-redux';

// import constants
import { CONFIRMATION_POPUP_HIDE } from '../../../constants/dispatchTypes';

// import actions
import { changeRecurringPaymentStatus } from '../../../pages/RecurringDonations/api/recurringDonationsSlice';

// import utils
import { _t } from '../../../utils/i18n';

// import components
import PopupContainer from './PopupContainer';

const PopupConfirmation = memo( () => {
  // get the global state
  const { tableData, isShown, number, id, paymentMethod } = useSelector( store => {
    const {
      recurringDonations: { tableData },
      confirmationPopup: { isShown, number, id, paymentMethod }
    } = store;

    return { tableData, isShown, number, id, paymentMethod };
  });

  // define the component state
  const [step, setStep]       = useState( 1 );
  const [isError, setIsError] = useState( false );
  const [confirmationCode, setConfirmationCode] = useState( '' );

  // define dispatch
  const dispatch = useDispatch();

  /**
   * Reset the popup to default settings
   */
  const resetPopup = () => {
    setStep( 1 );
    setIsError( false );
    setConfirmationCode( '' );
  };

  /**
   * Processing to close the popup
   */
  const handleClose = () => {
    dispatch( { type: CONFIRMATION_POPUP_HIDE, payload: false } );
    resetPopup();
  };

  /**
   * Processing to close the popup if ESC key was pressed
   */
  useEffect(() => {
    if ( isShown ) {
      const closePopupByEsc = e => { if ( e.keyCode === 27 ) handleClose() };

      window.addEventListener( 'keydown', closePopupByEsc );

      return () => window.removeEventListener( 'keydown', closePopupByEsc );
    }
  },[isShown] );

  /**
   * Processing to go to the next step
   */
  const handleProceed = () => {
    // go to the next step
    if ( step === 1 ) setStep( 2 );

    // validate the field value and dispatch the data
    if ( step === 2 ) {
      if ( number === confirmationCode ) {
        dispatch( changeRecurringPaymentStatus( id, paymentMethod, tableData ) )
          .then( () => resetPopup() );
      } else {
        setIsError( true );
      }
    }
  };

  /**
   * Processing when focus on the field
   */
  const handleFocus = () => { if ( isError ) setIsError( false ) };

  return (
    <PopupContainer isShown={ isShown }
                    handleClose={ () => handleClose() }
                    title={ _t( 'confirm_popup_title' ) }>
      { step === 1 ?
        <div className="popup__text-container">
          <p className="popup__text">{ _t( 'confirm_popup_text_line_1' ) }</p>
          <p className="popup__text">{ _t( 'confirm_popup_text_line_2' ) }</p>
        </div>
      :
        <div className="popup__text-container">
          <p className="popup__text">{ _t( 'confirm_popup_text_line_3' ) } <span className="popup__number">{ number }</span></p>
          <input className={ isError? "popup__input popup__input--is-error" : "popup__input" }
                 type="text"
                 onChange={ e => setConfirmationCode( e.currentTarget.value ) }
                 onFocus={ () => handleFocus() } />
          <span className={ isError ? "popup__error popup__error--is-shown" : "popup__error" }>{ _t( 'confirm_popup_error' ) }</span>
        </div>
      }
      <div className="popup__actions">
        <button className="popup__btn popup__btn--type-1"
                type="button"
                onClick={ () => handleClose() }>{ _t( 'confirm_popup_cancel' ) }</button>
        <button className="popup__btn popup__btn--type-2"
                type="button"
                onClick={ () => handleProceed() }>{ _t( 'confirm_popup_confirm' ) }</button>
      </div>
    </PopupContainer>
  )
});

export default PopupConfirmation;