/**
 * Keys and values for the EN language
 */
export const en = {
  // Login Form
  'login_title'         : 'Login Soulclick Manager',
  'login_subtitle'      : 'Welcome. Login with your credentials now:',
  'login_username_label': 'Username',
  'login_password_label': 'Password',
  'login_remember_label': 'Remember Me',
  'login_forgot_label'  : 'Forgot Password?',
  'login_button_text'   : 'Log in now',
  'login_meta_title'    : 'Login | Soulclick Manager',

  // Forgot Password Form
  'forgot_title'      : 'I Forgot My Password',
  'forgot_subtitle'   : 'Enter your email to reset your password',
  'forgot_email_label': 'Email',
  'forgot_button_text': 'Reset',

  // Sidebar: Menu
  'menu_statistics'         : 'Statistics',
  'menu_payments'           : 'Payments',
  'menu_payments_all'       : 'All',
  'menu_donation_type'      : 'Donation Type',
  'menu_payment_methods'    : 'Payment Methods',
  'menu_all_donations'      : 'All',
  'menu_recurring_initial'  : 'Recurring Initial',
  'menu_recurring_interval' : 'Recurring Interval',
  'menu_single_donations'   : 'Single Donations',
  'menu_crowd_donations'    : 'Crowdfunding',
  'menu_merchandise'        : 'Merchandise',
  'menu_shop'               : 'Shop',
  'menu_payment_methods_all': 'All',
  'menu_credit_cards'       : 'Credit Cards',
  'menu_e_wallet'           : 'E-Wallet',
  'menu_twint'              : 'Twint',
  'menu_postfinance'        : 'Postfinance',
  'menu_paypal'             : 'PayPal',
  'menu_crypto'             : 'Crypto',
  'menu_invoices'           : 'Invoices',
  'menu_goals'              : 'Goals',
  'menu_global_properties'  : 'Global Properties',
  'menu_languages'          : 'Languages',
  'menu_design_colors'      : 'Design & Colors',
  'menu_fonts'              : 'Fonts',
  'menu_global_texts'       : 'Global Texts',
  'menu_tag_manager'        : 'Tag Manager',
  'menu_means_of_payment'   : 'Means of Payment',
  'menu_products'           : 'Products',
  'menu_settings'           : 'Settings',
  'menu_emails'             : 'Emails',
  'menu_automation'         : 'Automations',
  'menu_title_dashboard'    : 'Dashboard',
  'menu_title_automation'   : 'Automation',
  'menu_title_platform'     : 'Platform',
  'menu_title_support'      : 'Support',
  'menu_title_logout'       : 'Logout',
  'menu_disabled_msg'       : 'This link is disabled',

  // Top Stats
  'topstats_total_amount'                        : 'Total Amount',
  'topstats_average_amount'                      : 'Average Amount',
  'topstats_total_donations'                     : 'Total Donations',
  'topstats_total_single'                        : 'Total Single Donations',
  'topstats_total_single_amount'                 : 'Total Single Amount',
  'topstats_total_recurring'                     : 'Total Recurring Donations',
  'topstats_total_recurring_amount'              : 'Total Recurring Donations Amount',
  'topstats_total_crowdfunding'                  : 'Total Crowdfunding',
  'topstats_total_crowdfunding_amount'           : 'Total Crowdfunding Amount',
  'topstats_total_recurring_init'                : 'Total Recurring Init',
  'topstats_total_recurring_init_amount'         : 'Total Recurring Init Amount',
  'topstats_total_active_recurring'              : 'Total Active Recurring',
  'topstats_total_inactive_recurring'            : 'Total Inactive Recurring',
  'topstats_total_invoice_recurring'             : 'Total Invoice Recurring',
  'topstats_total_online_recurring'              : 'Total Online Recurring',
  'topstats_total_recurring_interval'            : 'Total Recurring Interval',
  'topstats_total_recurring_interval_amount'     : 'Total Recurring Interval Amount',
  'topstats_total_recurring_interval_monthly'    : 'Total Recurring Interval Monthly',
  'topstats_total_recurring_interval_quarterly'  : 'Total Recurring Interval Quarterly',
  'topstats_total_recurring_interval_half_yearly': 'Total Recurring Interval Half-yearly',
  'topstats_total_recurring_interval_yearly'     : 'Total Recurring Interval Yearly',
  'topstats_from'                                : 'vs.',
  'topstats_30_days'                             : '30 Days',
  'topstats_yesterday'                           : 'Yesterday',
  'topstats_day_before_yesterday'                : 'Day Before Yesterday',
  'topstats_last_week'                           : 'Last Week',
  'topstats_last_month'                          : 'Last Month',
  'topstats_month_before_this_month'             : 'Month Before This Month',
  'topstats_last_quartal'                        : 'Last Quarter',
  'topstats_quartal_before_last_quartal'         : 'Quarter Before Last Quarter',
  'topstats_last_year'                           : 'Last Year',
  'topstats_year_before_last_year'               : 'Year Before Last Year',

  // Calendar
  'calendar_monday'      : 'Mon',
  'calendar_tuesday'     : 'Tue',
  'calendar_wednesday'   : 'Wed',
  'calendar_thursday'    : 'Thu',
  'calendar_friday'      : 'Fri',
  'calendar_saturday'    : 'Sat',
  'calendar_sunday'      : 'Sun',
  'calendar_january'     : 'January',
  'calendar_february'    : 'February',
  'calendar_march'       : 'March',
  'calendar_april'       : 'April',
  'calendar_may'         : 'May',
  'calendar_june'        : 'June',
  'calendar_july'        : 'July',
  'calendar_august'      : 'August',
  'calendar_september'   : 'September',
  'calendar_october'     : 'October',
  'calendar_november'    : 'November',
  'calendar_december'    : 'December',
  'calendar_today'       : 'Today',
  'calendar_yesterday'   : 'Yesterday',
  'calendar_last_7_days' : 'Last 7 Days',
  'calendar_last_30_days': 'Last 30 Days',
  'calendar_this_month'  : 'This Month',
  'calendar_last_month'  : 'Last Month',
  'calendar_this_quarter': 'This Quarter',
  'calendar_last_quarter': 'Last Quarter',
  'calendar_this_year'   : 'This Year',
  'calendar_last_year'   : 'Last Year',
  'calendar_custom_range': 'Custom Range',
  'calendar_apply'       : 'Apply',
  'calendar_cancel'      : 'Cancel',

  // Transaction Chart
  'transaction_chart_title'         : 'Transactions of Soulclick',
  'transaction_chart_name'          : 'Total Amount',
  'transaction_chart_january'       : 'Jan',
  'transaction_chart_february'      : 'Feb',
  'transaction_chart_march'         : 'Mar',
  'transaction_chart_april'         : 'Apr',
  'transaction_chart_may'           : 'May',
  'transaction_chart_june'          : 'Jun',
  'transaction_chart_july'          : 'Jul',
  'transaction_chart_august'        : 'Aug',
  'transaction_chart_september'     : 'Sep',
  'transaction_chart_october'       : 'Oct',
  'transaction_chart_november'      : 'Nov',
  'transaction_chart_december'      : 'Dec',
  'transaction_chart_monday'        : 'Monday',
  'transaction_chart_tuesday'       : 'Tuesday',
  'transaction_chart_wednesday'     : 'Wednesday',
  'transaction_chart_thursday'      : 'Thursday',
  'transaction_chart_friday'        : 'Friday',
  'transaction_chart_saturday'      : 'Saturday',
  'transaction_chart_sunday'        : 'Sunday',
  'transaction_chart_selection_zoom': 'Selection Zoom',
  'transaction_chart_zoom_in'       : 'Zoom In',
  'transaction_chart_zoom_out'      : 'Zoom Out',
  'transaction_chart_pan'           : 'Panning',
  'transaction_chart_reset'         : 'Reset Zoom',

  // Latest Donations
  'latest_donations_title': 'Latest Donations',

  // Product Total
  'product_total_title'       : 'Products Total Amount',
  'product_total_description' : 'Distribution',
  'product_total_amount_title': 'Product Type',

  // Product Performing
  'product_performing_title'      : 'Product donations',
  'product_performing_title_smart': 'Product donations Smart',
  'product_performing_description': 'Percentage',
  'product_performing_col_1'      : 'Top 5',
  'product_performing_col_2'      : 'Product',
  'product_performing_col_2_smart': 'Product Smart',
  'product_performing_col_3'      : 'Amount in',
  'product_performing_other'      : 'Other',

  // Resend Invoice Popup
  'resend_popup_title'      : 'Confirm your action',
  'resend_popup_text_line_1': 'Are you sure you want to re-send the invoice?',
  'resend_popup_text_line_2': 'Please confirm your action.',
  'resend_popup_confirm'    : 'Confirm',
  'resend_popup_cancel'     : 'Cancel',

  // Confirm Popup
  'confirm_popup_title'      : 'Confirm your action',
  'confirm_popup_text_line_1': 'Are you sure that you want to deactivate this payment?',
  'confirm_popup_text_line_2': 'Please confirm your action.',
  'confirm_popup_text_line_3': 'If you are safe, confirm by entering the order number below: ',
  'confirm_popup_error'      : 'The order number is not correct!',
  'confirm_popup_confirm'    : 'Confirm',
  'confirm_popup_cancel'     : 'Cancel',

  // Table
  'table_btn_copy'            : 'Copy',
  'table_btn_export'          : 'CSV',
  'table_btn_print'           : 'Print',
  'table_search'              : 'Search',
  'table_no_data'             : 'There are no entries matching your query.',
  'table_actions'             : 'Actions',
  'table_order_num'           : 'Order',
  'table_date'                : 'Date',
  'table_name'                : 'Name & Address',
  'table_amount'              : 'Amount',
  'table_amount_total'        : 'Total Amount',
  'table_payment_type'        : 'Payment Type',
  'table_product'             : 'Product',
  'table_product_smart'       : 'Product Smart',
  'table_donation_purpose'    : 'Donation Purpose',
  'table_payment_method'      : 'Payment Method',
  'table_payment_param'       : 'Payment Parameter',
  'table_status'              : 'Status',
  'table_interval'            : 'Interval',
  'table_next_payment'        : 'Next Payment',
  'table_currency'            : 'Currency',
  'table_all'                 : 'All',
  'table_rpp'                 : 'Rows per page',
  'table_inactive_tooltip'    : 'Make inactive',
  'table_resend_tooltip'      : 'Resend the Invoice',
  'table_active'              : 'Active',
  'table_inactive'            : 'Inactive',
  'table_quantity'            : 'Quantity',
  'table_from'                : 'from',
  'table_today'               : 'Today',
  'table_yesterday'           : 'Yesterday',
  'table_7_days'              : 'Last 7 Days',
  'table_this_month'          : 'This Month',
  'table_last_month'          : 'Last Month',
  'table_this_quarter'        : 'This Quarter',
  'table_last_quarter'        : 'Last Quarter',
  'table_this_year'           : 'This Year',
  'table_last_year'           : 'Last Year',
  'table_last_30_days'        : 'Last 30 Days',
  'table_interval_monthly'    : 'Monthly',
  'table_interval_quarterly'  : 'Quarterly',
  'table_interval_half-yearly': 'Half Yearly',
  'table_interval_yearly'     : 'Yearly',
  'table_no_purpose'          : 'Free Donation',
  'table_copy_no_support'     : 'This feature doesn\'t support by your browser',
  'table_copy_success'        : 'A table has been copied successfully!',
  'table_payment_type_one_off_donation'                   : 'Single Donation',
  'table_payment_type_recurring_donation'                 : 'Recurring Donation',
  'table_payment_type_recurring_donation_init_web'        : 'Recurring Donation Init Web',
  'table_payment_type_recurring_donation_init_invoice'    : 'Recurring Donation Init Invoice',
  'table_payment_type_recurring_donation_interval_web'    : 'Recurring Donation Interval Web',
  'table_payment_type_recurring_donation_interval_invoice': 'Recurring Donation Interval Invoice',
  'table_payment_type_crowdfunding'                       : 'Crowddonation',
  'table_payment_type_iframe_donation'                    : 'Form Donation',
  'table_payment_type_iframe_donation_init_web'           : 'Form Donation Init Web',
  'table_payment_type_iframe_donation_interval_web'       : 'Form Donation Interval Web',
  'table_payment_type_iframe_donation_init_invoice'       : 'Form Donation Init Invoice',
  'table_payment_type_iframe_donation_interval_invoice'   : 'Form Donation Interval Invoice',

  // All Payments Page
  'all_payments_title'                   : 'All Donations',
  'all_payments_description'             : '',
  'all_payments_top_products_title'      : 'Most popular product types',
  'all_payments_top_products_title_smart': 'Most popular product types Smart',
  'all_payments_top_products_name'       : 'Total Amount',
  'all_payments_table_title'             : 'All Transactions',
  'all_payments_table_all_times'         : 'All Times',
  'all_payments_table_all_time_btn'      : 'Get all time',
  'all_payments_table_description'       : '',

  // All Donations Page
  'all_donations_title'             : 'All Donations',
  'all_donations_description'       : '',
  'all_donations_top_products_title': 'Most popular product types',
  'all_donations_table_title'       : 'All Transactions',
  'all_donations_table_description' : '',

  // Recurring Donations Page
  'recurring_initial_title'            : 'Permanent Donations',
  'recurring_initial_description'      : '',
  'recurring_initial_table_title'      : 'Initial Permanent Donations',
  'recurring_initial_table_description': '',

  // Recurring Donations Interval Page
  'recurring_interval_title'            : 'Permanent Donations',
  'recurring_interval_description'      : '',
  'recurring_interval_forecast_title'   : 'Most popular product types',
  'recurring_interval_table_title'      : 'Initial Permanent Donations',
  'recurring_interval_table_description': '',
  'recurring_monthly_payments'          : 'Monthly Payments',
  'recurring_quarterly_payments'        : 'Quarterly Payments',
  'recurring_half_yearly_payments'      : 'Half-yearly Payments',
  'recurring_yearly_payments'           : 'Yearly Payments',

  // Single Donations Page
  'single_donations_title'            : 'Individual Donations',
  'single_donations_description'      : '',
  'single_donations_table_title'      : 'Individual Donations Transactions',
  'single_donations_table_description': '',

  // Crowdfunding Donations Page
  'crowdfunding_title'                  : 'Crowddonations',
  'crowdfunding_description'            : '',
  'crowdfunding_projects_title'         : 'All Projects',
  'crowdfunding_projects_description'   : 'Details of crowdfunding campaigns',
  'crowdfunding_projects_table_name'    : 'Project Name',
  'crowdfunding_projects_table_numbers' : 'Number of Donations',
  'crowdfunding_projects_table_donated' : 'Amount Donated',
  'crowdfunding_projects_table_required': 'Amount Required',
  'crowdfunding_projects_table_progress': 'Project Progress',
  'crowdfunding_projects_table_status'  : 'Status',
  'crowdfunding_projects_table_deadline': 'Deadline',
  'crowdfunding_project_published'      : 'Published',
  'crowdfunding_project_donated'        : 'donated',
  'crowdfunding_status_primary'         : 'On course',
  'crowdfunding_status_warning'         : 'On risk',
  'crowdfunding_status_failed'          : 'Failed',
  'crowdfunding_status_success'         : 'Successful',
  'crowdfunding_table_title'            : 'Crowdfunding Transactions',
  'crowdfunding_table_description'      : '',

  // All Payment Methods Page
  'all_methods_title'             : 'All Donations',
  'all_methods_description'       : '',
  'all_methods_top_products_title': 'Most popular payment methods',
  'all_methods_table_title'       : 'Payment Methods',
  'all_methods_table_description' : '',

  // Credit Card Payment Method Page
  'cc_title'             : 'Credit Card Payments',
  'cc_description'       : '',
  'cc_top_products_title': 'Most popular payment methods',
  'cc_table_title'       : 'Transactions',
  'cc_table_description' : '',

  // E-Wallet Payment Method Page
  'e_wallet_title'             : 'E-Wallet Payments',
  'e_wallet_description'       : '',
  'e_wallet_top_products_title': 'Most popular payment methods',
  'e_wallet_table_title'       : 'Transactions',
  'e_wallet_table_description' : '',

  // Twint Payment Method Page
  'twint_title'             : 'Twint Payments',
  'twint_description'       : '',
  'twint_top_products_title': 'Most popular payment methods',
  'twint_table_title'       : 'Transactions',
  'twint_table_description' : '',

  // Postfinance Payment Method Page
  'postfinance_title'             : 'Postfinance Payments',
  'postfinance_description'       : '',
  'postfinance_top_products_title': 'Most popular payment methods',
  'postfinance_table_title'       : 'Transactions',
  'postfinance_table_description' : '',

  // PayPal Payment Method Page
  'paypal_title'             : 'PayPal Payments',
  'paypal_description'       : '',
  'paypal_top_products_title': 'Most popular payment methods',
  'paypal_table_title'       : 'Transactions',
  'paypal_table_description' : '',

  // Invoice Payment Method Page
  'invoice_title'             : 'Invoice Payments',
  'invoice_description'       : '',
  'invoice_top_products_title': 'Most popular payment methods',
  'invoice_table_title'       : 'Transactions',
  'invoice_table_description' : '',

  // Crypto Payment Method Page
  'crypto_title'            : 'Crypto Payments',
  'crypto_description'      : '',
  'crypto_table_title'      : 'Transactions',
  'crypto_table_description': '',

  // Merchandise Page
  'merchandise_title'             : 'Merchandise Payments',
  'merchandise_description'       : '',
  'merchandise_top_products_title': 'Most Popular Products',
  'merchandise_table_title'       : 'Transactions',
  'merchandise_table_description' : '',

  // Shop Page
  'shop_title'             : 'Shop Payments',
  'shop_description'       : '',
  'shop_top_products_title': 'Most Popular Products',
  'shop_table_title'       : 'Transactions',
  'shop_table_description' : '',

  // 404 Error
  'error_title'      : '404: This page does not exist.',
  'error_btn_text'   : 'Back to homepage.',
  'error_meta_title' : 'Error 404 | Soulclick Manager',

  // Payment methods
  'payment_TWI': 'Twint',
  'payment_PAY': 'Google Pay',
  'payment_PFC': 'Postfinance Card',
  'payment_PEF': 'Postfinance E-Finance',
  'payment_VIS': 'VISA',
  'payment_ECA': 'Mastercard',
  'payment_APL': 'Apple Pay',
  'payment_PAP': 'PayPal',
  'payment_AMX': 'American Express',
  'payment_ALP': 'Alipay',
  'payment_AZP': 'Amazon Pay',
  'payment_CFY': 'Crypto Currencies',
  'payment_KLN': 'Klarna Invoice',
  'payment_DIB': 'Klarna Sofort',
  'payment_PSC': 'PaySafe Card',
  'payment_REK': 'Reka',
  'payment_SAM': 'Samsung Pay',
  'payment_ELV': 'Sepa',
  'payment_INV': 'QR-Invoice',

  // LSV
  'lsv_nav_lsv'                : 'LSV',
  'lsv_nav_debit'              : 'Direct Debit',
  'lsv_nav_history'            : 'History',
  'lsv_step1_title'            : 'Enter LSV order',
  'lsv_step2_title'            : 'LSV order is being recorded...',
  'lsv_step3_title'            : 'LSV order successfully entered!',
  'lsv_step_text'              : 'Step',
  'lsv_transaction_block_name' : 'Transaction Details',
  'lsv_bank_details_block_name': 'Bank details',
  'lsv_first_name_placeholder' : 'First Name',
  'lsv_first_name_error'       : 'Bitte Vornamen angeben',
  'lsv_last_name_placeholder'  : 'Last Name',
  'lsv_last_name_error'        : 'Bitte Nachnamen angeben',
  'lsv_street_placeholder'     : 'Street and Number',
  'lsv_street_error'           : 'Bitte Strasse & Nr. angeben',
  'lsv_city_placeholder'       : 'ZIP and City',
  'lsv_city_error'             : 'Bitte korrekte PLZ und Ort angeben',
  'lsv_iban_placeholder'       : 'IBAN',
  'lsv_iban_error'             : 'Bitte korrekte IBAN-Nr. eingeben.',
  'lsv_go_to_2nd_step'         : 'Next',
  'lsv_go_to_new'              : 'New order',
  'lsv_go_to_edit'             : 'Edit',

  // Other
  'fetch_error'       : 'Error - If this occurs repeatedly please contact our support',
  'invoice_sent_msg'  : 'The invoice was sent again through an automated email.',
  'status_changed_msg': 'Recurring donation successfully cancelled.',
  'no_responsive_msg' : 'The Soulclick manager was built for desktop devices - please change device.',
};