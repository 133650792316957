import React                   from 'react';
import { useSelector }         from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

// import constants
import { ROOT_PATH } from '../../../constants/paths';

// import components
import Statistics from '../../../pages/Statistics';
import Footer     from '../Footer/Footer';

export default function Main() {
  const { isCollapsed } = useSelector( state => state.sidebar );
  const { pathname }    = useLocation();

  return (
    <section className={ isCollapsed ? "main main--is-extended" : "main" }>
      { pathname === ROOT_PATH ? <Statistics /> : <Outlet /> }
      <Footer />
    </section>
  )
}