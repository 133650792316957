import React from 'react';

// import utils
import { _t } from '../../../utils/i18n';

// import icons
import { IconX } from '@tabler/icons-react';

const TableFilter = ( { filterText, onFilter, onClear } ) => (
  <div className="table__filter">
    <input className="table__filter__field"
           id="search"
           type="text"
           placeholder={ _t( 'table_search' ) }
           value={ filterText }
           onChange={ onFilter } />
    <span className="table__filter__clear"
          onClick={ onClear }>
      <IconX color="#73879C" size={ 14 } />
    </span>
  </div>
);

export default TableFilter;
