/**
 * Keys and values for the DE language
 */
export const de = {
  // Login Form
  'login_title'         : 'Login Soulclick Manager',
  'login_subtitle'      : 'Herzlich Willkommen. Logge dich jetzt mit deinen Details ein:',
  'login_username_label': 'E-Mail / Username',
  'login_password_label': 'Passwort',
  'login_remember_label': 'Angemeldet bleiben',
  'login_forgot_label'  : 'Passwort vergessen?',
  'login_button_text'   : 'Jetzt einloggen',
  'login_meta_title'    : 'Login | Soulclick Manager',

  // Forgot Password Form
  'forgot_title'      : 'Ich habe mein Passwort vergessen',
  'forgot_subtitle'   : 'Gib deine E-Mail-Adresse ein, um dein Passwort zurückzusetzen',
  'forgot_email_label': 'Email',
  'forgot_button_text': 'Zurücksetzen',

  // Sidebar: Menu
  'menu_statistics'         : 'Statistiken',
  'menu_payments'           : 'Zahlungen',
  'menu_payments_all'       : 'Alle',
  'menu_donation_type'      : 'Spendenart',
  'menu_payment_methods'    : 'Zahlungsmittel',
  'menu_all_donations'      : 'Alle',
  'menu_recurring_initial'  : 'Dauerspenden (1. Zahlung)',
  'menu_recurring_interval' : 'Dauerspenden (Wiederhol-Zlg.)',
  'menu_single_donations'   : 'Einzelspenden',
  'menu_crowd_donations'    : 'Crowddonation',
  'menu_merchandise'        : 'Merchandise',
  'menu_shop'               : 'Shop',
  'menu_payment_methods_all': 'Alle',
  'menu_credit_cards'       : 'Kreditkarte',
  'menu_e_wallet'           : 'E-Wallet',
  'menu_twint'              : 'Twint',
  'menu_postfinance'        : 'Postfinance',
  'menu_paypal'             : 'PayPal',
  'menu_crypto'             : 'Crypto',
  'menu_invoices'           : 'Auf Rechnung',
  'menu_goals'              : 'Ziele',
  'menu_global_properties'  : 'Globale Eigenschaften',
  'menu_languages'          : 'Sprachen',
  'menu_design_colors'      : 'Design & Farben',
  'menu_fonts'              : 'Schriftarten',
  'menu_global_texts'       : 'Globale Texte',
  'menu_tag_manager'        : 'Tag Manager',
  'menu_means_of_payment'   : 'Zahlungsmittel',
  'menu_products'           : 'Produkte',
  'menu_settings'           : 'Settings',
  'menu_emails'             : 'E-Mails',
  'menu_automation'         : 'Automation',
  'menu_title_dashboard'    : 'Dashboard',
  'menu_title_automation'   : 'Automation',
  'menu_title_platform'     : 'Plattform',
  'menu_title_support'      : 'Support',
  'menu_title_logout'       : 'Logout',
  'menu_disabled_msg'       : 'Dieses Feature ist inaktiv',

  // Top Stats
  'topstats_total_amount'                        : 'Einnahmen total',
  'topstats_average_amount'                      : '⌀-Spende',
  'topstats_total_donations'                     : 'Anzahl Spenden total',
  'topstats_total_single'                        : 'Anzahl Einzelspenden',
  'topstats_total_single_amount'                 : 'Einnahmen Einzelspenden',
  'topstats_total_recurring'                     : 'Anzahl Dauerspenden',
  'topstats_total_recurring_amount'              : 'Einnahmen Dauerspenden',
  'topstats_total_crowdfunding'                  : 'Anzahl Crowddonations',
  'topstats_total_crowdfunding_amount'           : 'Einnahmen Crowddonations',
  'topstats_total_recurring_init'                : 'Anzahl Dauerspenden (1. Zahlung)',
  'topstats_total_recurring_init_amount'         : 'Einnahmen Dauerspenden (1.Zahlung)',
  'topstats_total_active_recurring'              : 'Aktive Dauerspenden',
  'topstats_total_inactive_recurring'            : 'Inaktive Dauerspenden',
  'topstats_total_invoice_recurring'             : 'Dauerspenden auf Rechnung',
  'topstats_total_online_recurring'              : 'Dauerspenden Online',
  'topstats_total_recurring_interval'            : 'Anzahl Dauerspenden (Wiederhol-zlg.)',
  'topstats_total_recurring_interval_amount'     : 'Einnahmen Dauerspenden (Wiederhol-zlg.)',
  'topstats_total_recurring_interval_monthly'    : 'Dauerspenden monatlich',
  'topstats_total_recurring_interval_quarterly'  : 'Dauerspenden vierteljährlich',
  'topstats_total_recurring_interval_half_yearly': 'Dauerspenden halbjährlich',
  'topstats_total_recurring_interval_yearly'     : 'Dauerspenden jährlich',
  'topstats_from'                                : 'vs.',
  'topstats_30_days'                             : 'letzte 30 Tage',
  'topstats_yesterday'                           : 'gestern',
  'topstats_day_before_yesterday'                : 'vorgestern',
  'topstats_last_week'                           : 'letzte Woche',
  'topstats_last_month'                          : 'letzter Monat',
  'topstats_month_before_this_month'             : 'Vormonat',
  'topstats_last_quartal'                        : 'letztes Quartal',
  'topstats_quartal_before_last_quartal'         : 'Vorquartal',
  'topstats_last_year'                           : 'letztes Jahr',
  'topstats_year_before_last_year'               : 'Vorjahr',

  // Calendar
  'calendar_monday'      : 'Mo',
  'calendar_tuesday'     : 'Di',
  'calendar_wednesday'   : 'Mi',
  'calendar_thursday'    : 'Do',
  'calendar_friday'      : 'Fr',
  'calendar_saturday'    : 'Sa',
  'calendar_sunday'      : 'So',
  'calendar_january'     : 'Januar',
  'calendar_february'    : 'Februar',
  'calendar_march'       : 'März',
  'calendar_april'       : 'April',
  'calendar_may'         : 'Mai',
  'calendar_june'        : 'Juni',
  'calendar_july'        : 'Juli',
  'calendar_august'      : 'August',
  'calendar_september'   : 'September',
  'calendar_october'     : 'Oktober',
  'calendar_november'    : 'November',
  'calendar_december'    : 'Dezember',
  'calendar_today'       : 'Heute',
  'calendar_yesterday'   : 'Gestern',
  'calendar_last_7_days' : 'Letzte 7 Tage',
  'calendar_last_30_days': 'Letzte 30 Tage',
  'calendar_this_month'  : 'Aktueller Monat',
  'calendar_last_month'  : 'Letzter Monat',
  'calendar_this_quarter': 'Aktuelles Quartal',
  'calendar_last_quarter': 'Letztes Quartal',
  'calendar_this_year'   : 'Aktuelles Jahr',
  'calendar_last_year'   : 'Letztes Jahr',
  'calendar_custom_range': 'Benutzerdefiniert',
  'calendar_apply'       : 'Anwenden',
  'calendar_cancel'      : 'Stornieren',

  // Transaction Chart
  'transaction_chart_title'         : 'Transaktionen im Zeitverlauf',
  'transaction_chart_name'          : 'Einnahmen total',
  'transaction_chart_january'       : 'Jan',
  'transaction_chart_february'      : 'Feb',
  'transaction_chart_march'         : 'Mär',
  'transaction_chart_april'         : 'Apr',
  'transaction_chart_may'           : 'Mai',
  'transaction_chart_june'          : 'Jun',
  'transaction_chart_july'          : 'Jul',
  'transaction_chart_august'        : 'Aug',
  'transaction_chart_september'     : 'Sep',
  'transaction_chart_october'       : 'Okt',
  'transaction_chart_november'      : 'Nov',
  'transaction_chart_december'      : 'Dez',
  'transaction_chart_monday'        : 'Montag',
  'transaction_chart_tuesday'       : 'Dienstag',
  'transaction_chart_wednesday'     : 'Mittwoch',
  'transaction_chart_thursday'      : 'Donnerstag',
  'transaction_chart_friday'        : 'Freitag',
  'transaction_chart_saturday'      : 'Samstag',
  'transaction_chart_sunday'        : 'Sonntag',
  'transaction_chart_selection_zoom': 'Auswählen',
  'transaction_chart_zoom_in'       : 'Vergrössern',
  'transaction_chart_zoom_out'      : 'Verkleinern',
  'transaction_chart_pan'           : 'Verschieben',
  'transaction_chart_reset'         : 'Zurücksetzen',

  // Latest Donations
  'latest_donations_title': 'Letzte Transaktionen',

  // Product Total
  'product_total_title'       : 'Einnahmen nach Spendenart',
  'product_total_description' : '',
  'product_total_amount_title': '',

  // Product Performing
  'product_performing_title'      : 'Beliebteste Produkte',
  'product_performing_title_smart': 'Beliebteste Produkte Smart',
  'product_performing_description': '',
  'product_performing_col_1'      : 'Übersicht',
  'product_performing_col_2'      : 'Produkt',
  'product_performing_col_2_smart': 'Produkt Smart',
  'product_performing_col_3'      : '',
  'product_performing_other'      : 'Andere',

  // Resend Invoice Popup
  'resend_popup_title'      : 'Rechnung erneut senden',
  'resend_popup_text_line_1': 'Bist du sicher, dass du diese Rechnung erneut senden möchtest?',
  'resend_popup_text_line_2': '',
  'resend_popup_confirm'    : 'Bestätigen',
  'resend_popup_cancel'     : 'Abbrechen',

  // Confirm Popup
  'confirm_popup_title'      : 'Zahlung deaktivieren',
  'confirm_popup_text_line_1': 'Bist du sicher, dass du diese Zahlung deaktivieren möchtest?',
  'confirm_popup_text_line_2': '',
  'confirm_popup_text_line_3': 'Falls du sicher bist, bestätige dies durch die Eingabe der Bestellnummer: ',
  'confirm_popup_error'      : 'Die Bestellnummer ist nicht korrekt!',
  'confirm_popup_confirm'    : 'Bestätigen',
  'confirm_popup_cancel'     : 'Abbrechen',

  // Table
  'table_btn_copy'            : 'Kopieren',
  'table_btn_export'          : 'CSV Export',
  'table_btn_print'           : 'Drucken',
  'table_search'              : 'Suchen',
  'table_no_data'             : 'Unter diesem Suchbegriff gibt es keine Einträge.',
  'table_actions'             : 'Aktion',
  'table_order_num'           : 'ID',
  'table_date'                : 'Datum',
  'table_name'                : 'Spender:in',
  'table_amount'              : 'Betrag',
  'table_amount_total'        : 'Total Betrag',
  'table_payment_type'        : 'Spenden-Typ',
  'table_product'             : 'Produkt',
  'table_product_smart'       : 'Produkt Smart',
  'table_donation_purpose'    : 'Spendenzweck',
  'table_payment_method'      : 'Zahlungsmittel',
  'table_payment_param'       : 'Zahlungsparameter',
  'table_status'              : 'Status',
  'table_interval'            : 'Interval',
  'table_next_payment'        : 'Nächste Ausführung',
  'table_currency'            : 'Währung',
  'table_all'                 : 'Alle',
  'table_rpp'                 : 'Zeilen pro Seite',
  'table_inactive_tooltip'    : 'Deaktivieren',
  'table_resend_tooltip'      : 'Rechnung erneut senden',
  'table_active'              : 'Aktiv',
  'table_inactive'            : 'Inaktiv',
  'table_quantity'            : 'Anzahl',
  'table_from'                : '',
  'table_today'               : 'Heute',
  'table_yesterday'           : 'Gestern',
  'table_7_days'              : 'der letzten 7 Tage',
  'table_this_month'          : 'des aktuellen Monats',
  'table_last_month'          : 'des letzten Monats',
  'table_this_quarter'        : 'des aktuellen Quartals',
  'table_last_quarter'        : 'des letzten Quartals',
  'table_this_year'           : 'des aktuellen Jahres',
  'table_last_year'           : 'des letzten Jahres',
  'table_last_30_days'        : 'der letzten 30 Tage',
  'table_interval_monthly'    : 'Monatlich',
  'table_interval_quarterly'  : 'Vierteljährlich',
  'table_interval_half-yearly': 'Halbjährlich',
  'table_interval_yearly'     : 'Jährlich',
  'table_no_purpose'          : 'Freie Spende',
  'table_copy_no_support'     : 'Kopieren wird von deinem Browser nicht unterstützt.',
  'table_copy_success'        : 'Daten kopiert.',
  'table_payment_type_one_off_donation'                   : 'Einmalspende',
  'table_payment_type_recurring_donation_init_web'        : 'Dauerspende (1.Zahlung) Web',
  'table_payment_type_recurring_donation_init_invoice'    : 'Dauerspende (1.Zahlung) Rechnung',
  'table_payment_type_recurring_donation_interval_web'    : 'Dauerspende (Wiederholzahlung) Online',
  'table_payment_type_recurring_donation_interval_invoice': 'Dauerspende (Wiederholzahlung) Rechnung',
  'table_payment_type_crowdfunding'                       : 'Crowddonation',
  'table_payment_type_iframe_donation'                    : 'Formularspende',
  'table_payment_type_iframe_donation_init_web'           : 'Formularspende (1.Zahlung) Web',
  'table_payment_type_iframe_donation_interval_web'       : 'Formularspende (Wiederholzahlung) Web',
  'table_payment_type_iframe_donation_init_invoice'       : 'Formularspende (1.Zahlung) Rechnung',
  'table_payment_type_iframe_donation_interval_invoice'   : 'Formularspende (Wiederholzahlung) Rechnung',

  // All Payments Page
  'all_payments_title'                   : 'Alle Zahlungen',
  'all_payments_description'             : '',
  'all_payments_top_products_title'      : 'Beliebteste Produkte',
  'all_payments_top_products_title_smart': 'Beliebteste Produkte Smart',
  'all_payments_top_products_name'       : 'Gesamtmenge',
  'all_payments_table_title'             : 'Transaktionen',
  'all_payments_table_all_times'         : 'Jederzeit',
  'all_payments_table_all_time_btn'      : 'Alle Einträge',
  'all_payments_table_description'       : '',

  // All Donations Page
  'all_donations_title'             : 'Alle Spendenarten',
  'all_donations_description'       : '',
  'all_donations_top_products_title': 'Beliebteste Spendenarten',
  'all_donations_table_title'       : 'Transaktionen',
  'all_donations_table_description' : '',

  // Recurring Donations Page
  'recurring_initial_title'            : 'Alle Dauerspenden (1. Zahlung)',
  'recurring_initial_description'      : '',
  'recurring_initial_table_title'      : 'Transaktionen',
  'recurring_initial_table_description': '',

  // Recurring Donations Interval Page
  'recurring_interval_title'            : 'Alle Dauerspenden (Wiederholzahlung)',
  'recurring_interval_description'      : '',
  'recurring_interval_forecast_title'   : 'Forecast nächste 12 Monate',
  'recurring_interval_table_title'      : 'Transaktionen',
  'recurring_interval_table_description': '',
  'recurring_monthly_payments'          : 'Monatliche Zahlungen',
  'recurring_quarterly_payments'        : 'Vierteljährliche Zahlungen',
  'recurring_half_yearly_payments'      : 'Halbjahreszahlungen',
  'recurring_yearly_payments'           : 'Jährliche Zahlungen',

  // Single Donations Page
  'single_donations_title'            : 'Alle Einzelspenden',
  'single_donations_description'      : '',
  'single_donations_table_title'      : 'Transaktionen',
  'single_donations_table_description': '',

  // Crowdfunding Donations Page
  'crowdfunding_title'                  : 'Alle Crowddonations',
  'crowdfunding_description'            : '',
  'crowdfunding_projects_title'         : 'Alle Projekte',
  'crowdfunding_projects_description'   : 'Details der Crowdfunding Aktionen',
  'crowdfunding_projects_table_name'    : 'Projekt Name',
  'crowdfunding_projects_table_numbers' : 'Anzahl Spenden',
  'crowdfunding_projects_table_donated' : 'Betrag gespendet',
  'crowdfunding_projects_table_required': 'Spendenbedarf',
  'crowdfunding_projects_table_progress': 'Fortschritt',
  'crowdfunding_projects_table_status'  : 'Status',
  'crowdfunding_projects_table_deadline': 'Deadline',
  'crowdfunding_project_published'      : 'Publiziert am',
  'crowdfunding_project_donated'        : 'Gespendet',
  'crowdfunding_status_primary'         : 'Auf Kurs',
  'crowdfunding_status_warning'         : 'Kritisch',
  'crowdfunding_status_failed'          : 'Fehlgeschlagen',
  'crowdfunding_status_success'         : 'Erfolgreich',
  'crowdfunding_table_title'            : 'Transaktionen',
  'crowdfunding_table_description'      : '',

  // All Payment Methods Page
  'all_methods_title'             : 'Alle Spenden',
  'all_methods_description'       : '',
  'all_methods_top_products_title': 'Beliebteste Zahlungsarten',
  'all_methods_table_title'       : 'Zahlungsarten',
  'all_methods_table_description' : '',

  // Credit Card Payment Method Page
  'cc_title'             : 'Kreditkarten Zahlungen',
  'cc_description'       : '',
  'cc_top_products_title': 'Beliebteste Zahlungsarten',
  'cc_table_title'       : 'Transaktionen',
  'cc_table_description' : '',

  // E-Wallet Payment Method Page
  'e_wallet_title'             : 'E-Wallet Zahlungen',
  'e_wallet_description'       : '',
  'e_wallet_top_products_title': 'Beliebteste Zahlungsarten',
  'e_wallet_table_title'       : 'Transaktionen',
  'e_wallet_table_description' : '',

  // Twint Payment Method Page
  'twint_title'             : 'Twint Zahlungen',
  'twint_description'       : '',
  'twint_top_products_title': 'Beliebteste Zahlungsarten',
  'twint_table_title'       : 'Transaktionen',
  'twint_table_description' : '',

  // Postfinance Payment Method Page
  'postfinance_title'             : 'Postfinance Zahlungen',
  'postfinance_description'       : '',
  'postfinance_top_products_title': 'Beliebteste Zahlungsarten',
  'postfinance_table_title'       : 'Transaktionen',
  'postfinance_table_description' : '',

  // PayPal Payment Method Page
  'paypal_title'             : 'PayPal Zahlungen',
  'paypal_description'       : '',
  'paypal_top_products_title': 'Beliebteste Zahlungsarten',
  'paypal_table_title'       : 'Transaktionen',
  'paypal_table_description' : '',

  // Invoice Payment Method Page
  'invoice_title'             : 'Rechnungs-Zahlungen',
  'invoice_description'       : '',
  'invoice_top_products_title': 'Beliebteste Zahlungsarten',
  'invoice_table_title'       : 'Transaktionen',
  'invoice_table_description' : '',

  // Crypto Payment Method Page
  'crypto_title'            : 'Crypto Zahlungen',
  'crypto_description'      : '',
  'crypto_table_title'      : 'Transaktionen',
  'crypto_table_description': '',

  // Merchandise Page
  'merchandise_title'             : 'Merchandise Spenden',
  'merchandise_description'       : '',
  'merchandise_top_products_title': 'Beliebteste Produkte',
  'merchandise_table_title'       : 'Transaktionen',
  'merchandise_table_description' : '',

  // Shop Page
  'shop_title'             : 'Shop Spenden',
  'shop_description'       : '',
  'shop_top_products_title': 'Beliebteste Produkte',
  'shop_table_title'       : 'Transaktionen',
  'shop_table_description' : '',

  // 404 Error
  'error_title'         : '404: Diese Seite existiert nicht.',
  'error_btn_text'      : 'Zurück zur Startseite',
  'error_meta_title'    : '404 Fehler | Soulclick Manager',

  // Payment methods
  'payment_TWI': 'Twint',
  'payment_PAY': 'Google Pay',
  'payment_PFC': 'Postfinance Card',
  'payment_PEF': 'Postfinance E-Finance',
  'payment_VIS': 'VISA',
  'payment_ECA': 'Mastercard',
  'payment_APL': 'Apple Pay',
  'payment_PAP': 'PayPal',
  'payment_AMX': 'American Express',
  'payment_ALP': 'Alipay',
  'payment_AZP': 'Amazon Pay',
  'payment_CFY': 'Krypto Währungen',
  'payment_KLN': 'Klarna Rechnung',
  'payment_DIB': 'Klarna Sofort',
  'payment_PSC': 'PaySafe Card',
  'payment_REK': 'Reka',
  'payment_SAM': 'Samsung Pay',
  'payment_ELV': 'SEPA',
  'payment_INV': 'QR-Rechnung',

  // LSV
  'lsv_nav_lsv'                : 'LSV',
  'lsv_nav_debit'              : 'Direct Debit',
  'lsv_nav_history'            : 'History',
  'lsv_step1_title'            : 'LSV Auftrag erfassen',
  'lsv_step2_title'            : 'LSV-Auftrag wird erfasst...',
  'lsv_step3_title'            : 'LSV-Auftrag erfolgreich erfasst!',
  'lsv_step_text'              : 'Schritt',
  'lsv_transaction_block_name' : 'Transaktionsdetails',
  'lsv_bank_details_block_name': 'Bankangaben',
  'lsv_first_name_placeholder' : 'Vorname',
  'lsv_first_name_error'       : 'Bitte Vornamen angeben',
  'lsv_last_name_placeholder'  : 'Nachname',
  'lsv_last_name_error'        : 'Bitte Nachnamen angeben',
  'lsv_street_placeholder'     : 'Strasse & Nr.',
  'lsv_street_error'           : 'Bitte Strasse & Nr. angeben',
  'lsv_city_placeholder'       : 'PLZ & Ort',
  'lsv_city_error'             : 'Bitte korrekte PLZ und Ort angeben',
  'lsv_iban_placeholder'       : 'IBAN',
  'lsv_iban_error'             : 'Bitte korrekte IBAN-Nr. eingeben.',
  'lsv_go_to_2nd_step'         : 'Weiter',
  'lsv_go_to_new'              : 'Neu erfassen',
  'lsv_go_to_edit'             : 'Bearbeiten',

  // Other
  'fetch_error'       : 'Fehlermeldung - Falls dies wiederholt auftritt kontaktiere bitte unseren Support.',
  'invoice_sent_msg'  : 'Die Rechnung wurde erneut via E-Mail verschickt.',
  'status_changed_msg': 'Die Dauerspende wurde erfolgreich deaktiviert.',
  'no_responsive_msg' : 'Der Soulclick Manger wurde für Desktop-Geräte optimiert - Bitte wechsle das Gerät',
};