import React           from 'react';
import { useSelector } from 'react-redux';

// import components
import SidebarCollapse  from '../SidebarToggle/SidebarCollapse';
import SidebarNavTop    from './SidebarNavTop';
import SidebarNavBottom from './SidebarNavBottom';

export default function SidebarNav() {
  const { isCollapsed } = useSelector( state => state.sidebar );

  return (
    <section className={ isCollapsed ? 'sidebar-nav sidebar-nav--is-collapsed' : 'sidebar-nav' }>
      <SidebarCollapse />
      <SidebarNavTop />
      <SidebarNavBottom />
    </section>
  );
}