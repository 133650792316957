import { createSlice } from '@reduxjs/toolkit'

export const tableSlice = createSlice({
  name: 'table',
  initialState: {
    rowsPerPage: 10,
  },
  reducers: {
    changeRowsPerPage: ( state, action ) => {
      state.rowsPerPage = action.payload;
    }
  }
});

export default tableSlice.reducer;