import React, { useEffect }         from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation }              from 'react-router-dom';

// import constants
import { IDLE_STATUS }      from '../../constants/loadingStatuses';
import { LANDING_LOCATION } from '../../constants/componentLocation';

// import actions
import { fetchLanding } from './api/statisticsSlice';

// import utils
import { getUser, getUserPackage, isUserRemembered } from '../../utils/Auth';
import { _t }                                        from '../../utils/i18n';

// import helpers
import getLocation               from '../../helpers/getLocation';
import { hideErrorNotification } from '../../helpers/hideErrorNotification';

// import components
import ContainerWrapper        from '../../components/common/ContainerWrapper/ContainerWrapper';
import Chart                   from '../../components/elements/Chart/Chart';
import LatestDonations         from '../../components/elements/LatestDonations/LatestDonations';
import ProductTypesTotalAmount from '../../components/elements/ProductTypesTotalAmount/ProductTypesTotalAmount';
import ProductsPerforming      from '../../components/elements/ProductsPerforming/ProductsPerforming';
import ModuleWrapper           from '../../components/common/ModuleWrapper/ModuleWrapper';
import Calendar                from '../../components/elements/Calendar/Calendar';

export default function Landing() {
  // define dispatch
  const dispatch = useDispatch();

  // define location
  const location    = useLocation();
  const { prevUrl } = getLocation();

  // get the global state
  const { status, isShown, metaTitle, isRemembered } = useSelector( state => {
    const {
      landing: { status, metaTitle },
      toast: { isShown },
      login: { isRemembered }
    } = state;

    return { status, isShown, metaTitle, isRemembered };
  });

  // fetch the landing data from API
  useEffect( () => { if ( status === IDLE_STATUS ) dispatch( fetchLanding() ) }, [status, dispatch] );

  // check if toast is shown and hide it
  useEffect( () => hideErrorNotification( dispatch, isShown, prevUrl ), [location] );

  // get the user currency
  const { currency } = getUser( isRemembered );

  return (
    <ContainerWrapper metaTitle={ metaTitle }
                      location={ LANDING_LOCATION }
                      topStats>
      <div className="row">
        <div className="col col-xs-12">
          <ModuleWrapper title={ _t( 'transaction_chart_title' ) }>
            <Calendar location={ LANDING_LOCATION } />
            <Chart chartType="area" />
          </ModuleWrapper>
        </div>
      </div>

      <div className="row">
        <div className="col col-xs-4">
          <ModuleWrapper title={ _t( 'latest_donations_title' ) }>
            <LatestDonations />
          </ModuleWrapper>
        </div>

        <div className="col col-xs-4">
          <ModuleWrapper title={ _t( 'product_total_title' ) }
                         description={ `${ _t( 'product_total_description' ) } in ${ currency }` }>
            <ProductTypesTotalAmount />
          </ModuleWrapper>
        </div>

        <div className="col col-xs-4">
          <ModuleWrapper title={ getUserPackage( isUserRemembered() ) === 'smart' ? _t( 'product_performing_title_smart' ) : _t( 'product_performing_title' ) }
                         description={ _t( 'product_performing_description' ) }>
            <ProductsPerforming />
          </ModuleWrapper>
        </div>
      </div>
    </ContainerWrapper>
  )
}