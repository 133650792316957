import React, { memo } from 'react';

// import helper
import { swissNumberFormat } from '../../../helpers/swissNumberFormat';

// import utils
import { _t } from '../../../utils/i18n';

const ProductsPerformingProduct = memo( ( { data, color } ) => {
  const { title, amount } = data;
  const name = title === 'Other' ? _t( 'product_performing_other' ) : title;

  return (
    <div className="products-performing__products-list__item">
      <span className="products-performing__products-list__item__color" style={{ backgroundColor: color }} />
      <span className="products-performing__products-list__item__name" title={ name }>{ name }</span>
      <span className="products-performing__products-list__item__amount">{ swissNumberFormat( amount ) }</span>
    </div>
  )
});

export default ProductsPerformingProduct;