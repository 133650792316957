// import constants
import {
  ROOT_PATH, ALL_PAYMENTS_PATH, ALL_DONATIONS_PATH, RECURRING_DONATIONS_PATH, RECURRING_DONATIONS_INTERVAL_PATH,
  SINGLE_DONATIONS_PATH, CROWDFUNDING_PATH, MERCHANDISE_PATH, SHOP_PATH, ALL_PAYMENT_METHODS_PATH,
  CREDIT_CARD_METHOD_PATH, EWALLET_METHOD_PATH, TWINT_METHOD_PATH, POSTFINANCE_METHOD_PATH, PAYPAL_METHOD_PATH,
  INVOICE_METHOD_PATH
} from '../../../constants/paths';

// import utils
import { _t }                        from '../../../utils/i18n';
import { getUser, isUserRemembered } from '../../../utils/Auth';

// get user data
const isRemembered = isUserRemembered();
const { userPackage, merchandise } = getUser( isRemembered );

/**
 * Get available donation types depends from user package
 *
 * @return array
 */
const prepareDonationTypes = () => {
  switch ( userPackage ) {
    case 'crowd':
      return [
        {
          title: _t( 'menu_all_donations' ),
          link: ALL_DONATIONS_PATH,
          isDisabled: false,
        },
        {
          title: _t( 'menu_recurring_initial' ),
          link: RECURRING_DONATIONS_PATH,
          isDisabled: false,
        },
        {
          title: _t( 'menu_recurring_interval' ),
          link: RECURRING_DONATIONS_INTERVAL_PATH,
          isDisabled: false,
        },
        {
          title: _t( 'menu_single_donations' ),
          link: SINGLE_DONATIONS_PATH,
          isDisabled: false,
        },
        {
          title: _t( 'menu_crowd_donations' ),
          link: CROWDFUNDING_PATH,
          isDisabled: false,
        },
      ];
    case 'shop':
      return [
        {
          title: _t( 'menu_all_donations' ),
          link: ALL_DONATIONS_PATH,
          isDisabled: false,
        },
        {
          title: _t( 'menu_recurring_initial' ),
          link: RECURRING_DONATIONS_PATH,
          isDisabled: false,
        },
        {
          title: _t( 'menu_recurring_interval' ),
          link: RECURRING_DONATIONS_INTERVAL_PATH,
          isDisabled: false,
        },
        {
          title: _t( 'menu_single_donations' ),
          link: SINGLE_DONATIONS_PATH,
          isDisabled: false,
        },
        {
          title: _t( 'menu_crowd_donations' ),
          link: CROWDFUNDING_PATH,
          isDisabled: false,
        },
        {
          title: _t( 'menu_shop' ),
          link: SHOP_PATH,
          isDisabled: true,
        },
      ];
    default:
      return [
        {
          title: _t( 'menu_all_donations' ),
          link: ALL_DONATIONS_PATH,
          isDisabled: false,
        },
        {
          title: _t( 'menu_recurring_initial' ),
          link: RECURRING_DONATIONS_PATH,
          isDisabled: false,
        },
        {
          title: _t( 'menu_recurring_interval' ),
          link: RECURRING_DONATIONS_INTERVAL_PATH,
          isDisabled: false,
        },
        {
          title: _t( 'menu_single_donations' ),
          link: SINGLE_DONATIONS_PATH,
          isDisabled: false,
        },
      ];
  }
};

/**
 * The donation types sub menu structure
 */
const donationTypes = merchandise ?
  [...prepareDonationTypes(),
    {
      title: _t( 'menu_merchandise' ),
      link: MERCHANDISE_PATH,
      isDisabled: true,
    }
  ] : prepareDonationTypes();

/**
 * The Dashboard menu structure
 */
export const dashboardMenu = [
  {
    title: _t( 'menu_statistics' ),
    link: ROOT_PATH,
    hasChildren: false,
    isDisabled: false,
  },
  {
    title: _t( 'menu_payments' ),
    link: ALL_PAYMENTS_PATH,
    hasChildren: false,
    isDisabled: false,
  },
  {
    title: _t( 'menu_donation_type' ),
    link: 'menu_donation_type',
    hasChildren: true,
    isDisabled: false,
    children: donationTypes,
  },
  {
    title: _t( 'menu_payment_methods' ),
    link: 'menu_payment_methods',
    hasChildren: true,
    isDisabled: false,
    children: [
      {
        title: _t( 'menu_payment_methods_all' ),
        link: ALL_PAYMENT_METHODS_PATH,
        isDisabled: false,
      },
      {
        title: _t( 'menu_credit_cards' ),
        link: CREDIT_CARD_METHOD_PATH,
        isDisabled: false,
      },
      {
        title: _t( 'menu_e_wallet' ),
        link: EWALLET_METHOD_PATH,
        isDisabled: false,
      },
      {
        title: _t( 'menu_twint' ),
        link: TWINT_METHOD_PATH,
        isDisabled: false,
      },
      {
        title: _t( 'menu_postfinance' ),
        link: POSTFINANCE_METHOD_PATH,
        isDisabled: false,
      },
      {
        title: _t( 'menu_paypal' ),
        link: PAYPAL_METHOD_PATH,
        isDisabled: false,
      },
      {
        title: _t( 'menu_invoices' ),
        link: INVOICE_METHOD_PATH,
        isDisabled: false,
      },
    ],
  }
];

/**
 * The Platform menu structure
 */
export const platformMenu = [
  {
    title: _t( 'menu_goals' ),
    link: 'menu_goals',
    hasChildren: false,
    isDisabled: true,
  },
  {
    title: _t( 'menu_global_properties' ),
    link: 'menu_global_properties',
    hasChildren: true,
    isDisabled: true,
    children: [
      {
        title: _t( 'menu_languages' ),
        link: 'menu_languages',
        isDisabled: true,
      },
      {
        title: _t( 'menu_design_colors' ),
        link: 'menu_design_colors',
        isDisabled: true,
      },
      {
        title: _t( 'menu_fonts' ),
        link: 'menu_fonts',
        isDisabled: true,
      },
      {
        title: _t( 'menu_global_texts' ),
        link: 'menu_global_texts',
        isDisabled: true,
      },
      {
        title: _t( 'menu_tag_manager' ),
        link: 'menu_tag_manager',
        isDisabled: true,
      },
      {
        title: _t( 'menu_means_of_payment' ),
        link: 'menu_means_of_payment',
        isDisabled: true,
      },
    ]
  },
  {
    title: _t( 'menu_products' ),
    link: 'menu_products',
    hasChildren: false,
    isDisabled: true,
  },
];

/**
 * The Automation menu structure
 */
export const automationMenu = [
  {
    title: _t( 'menu_settings' ),
    link: 'menu_settings',
    hasChildren: false,
    isDisabled: true,
  },
  {
    title: _t( 'menu_emails' ),
    link: 'menu_emails',
    hasChildren: false,
    isDisabled: true,
  },
  {
    title: _t( 'menu_automation' ),
    link: 'menu_automation',
    hasChildren: false,
    isDisabled: true,
  },
];