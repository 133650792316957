import React, { memo, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch }                  from 'react-redux';
import DataTable                                     from 'react-data-table-component';

// import constants
import { SUCCEEDED_STATUS }           from '../../../constants/loadingStatuses';
import { TABLE_CHANGE_ROWS_PER_PAGE } from '../../../constants/dispatchTypes';
import {
  ALL_DONATIONS_LOCATION, RECURRING_DONATIONS_LOCATION, SINGLE_DONATIONS_LOCATION, CROWDFUNDING_LOCATION,
  MERCHANDISE_LOCATION, SHOP_LOCATION, ALL_PAYMENT_METHODS_LOCATION, CREDIT_CARD_PAYMENTS_LOCATION,
  EWALLET_PAYMENTS_LOCATION, TWINT_PAYMENTS_LOCATION, POSTFINANCE_PAYMENTS_LOCATION, CRYPTO_PAYMENTS_LOCATION,
  INVOICE_PAYMENTS_LOCATION, PAYPAL_PAYMENTS_LOCATION, RECURRING_DONATIONS_INTERVAL_LOCATION
} from '../../../constants/componentLocation';

//import utils
import { _t } from '../../../utils/i18n';

// import components
import TableFilter    from './TableFilter';
import TableNoData    from './TableNoData';
import TableCopy      from './TableCopy';
import TableExportCSV from './TableExportCSV';
import TablePrint     from './TablePrint';
import TableSkeleton  from './TableSkeleton';

const Table = memo( ( { location, defaultSortFieldId, defaultSort, columns, exportFields } ) => {
  const { rowsPerPage } = useSelector( state => state.table );
  // get state related to location
  const { status, tableStatus, tableData, tableOptions } = useSelector( state => {
    switch( location ) {
      case ALL_DONATIONS_LOCATION:
        return state.allDonations;
      case RECURRING_DONATIONS_LOCATION:
        return state.recurringDonations;
      case RECURRING_DONATIONS_INTERVAL_LOCATION:
        return state.recurringDonationsInterval;
      case SINGLE_DONATIONS_LOCATION:
        return state.singleDonations;
      case CROWDFUNDING_LOCATION:
        return state.crowdfunding;
      case MERCHANDISE_LOCATION:
        return state.merchandise;
      case SHOP_LOCATION:
        return state.shop;
      case ALL_PAYMENT_METHODS_LOCATION:
        return state.allMethods;
      case CREDIT_CARD_PAYMENTS_LOCATION:
        return state.creditCardPayments;
      case EWALLET_PAYMENTS_LOCATION:
        return state.ewalletPayments;
      case TWINT_PAYMENTS_LOCATION:
        return state.twintPayments;
      case POSTFINANCE_PAYMENTS_LOCATION:
        return state.postfinancePayments;
      case CRYPTO_PAYMENTS_LOCATION:
        return state.cryptoPayments;
      case PAYPAL_PAYMENTS_LOCATION:
        return state.paypalPayments;
      case INVOICE_PAYMENTS_LOCATION:
        return state.invoicePayments;
      /* falls through */
      default:
        return state.allPayments;
    }
  });

  // define dispatch
  const dispatch = useDispatch();

  // define the component state
  const [resetPaginationToggle, setResetPaginationToggle] = useState( false );
  const [filterText, setFilterText]                       = useState( '' );
  const [actionsData, setActionsData]                     = useState( [] );

  // convert table data to filtered date
  const filteredData = tableData.filter( item => JSON.stringify( item ).toLowerCase().indexOf( filterText.toLowerCase() ) !== -1 );

  // update actions data to be equals to table or filtered data
  useEffect(() => {
    setActionsData( tableData.filter( item => JSON.stringify( item ).toLowerCase().indexOf( filterText.toLowerCase() ) !== -1 ) );
  }, [filterText, tableData] );

  // add Filter to the Table
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if ( filterText ) {
        setResetPaginationToggle( !resetPaginationToggle );
        setFilterText( '' );
      }
    };

    return (
      <TableFilter onFilter={ e => setFilterText( e.target.value ) }
                   onClear={ handleClear }
                   filterText={ filterText } />
    );
  }, [filterText, resetPaginationToggle] );

  // add Actions to the Table
  const actionsComponent = useMemo( () => {
    return (
      <div className="table__actions">
        <TableCopy data={ actionsData } fields={ exportFields } />
        <TableExportCSV data={ actionsData } fields={ exportFields } />
        <TablePrint data={ actionsData } fields={ exportFields } />
      </div>
    )
  }, [actionsData, exportFields] );

  // no data component
  const noDataComponent = useMemo( () => <TableNoData />, [] );

  // define pagination options
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: _t( 'table_all' ),
    rowsPerPageText: `${ _t( 'table_rpp' ) }:`
  };

  return (
    <div className="table">
      { status === SUCCEEDED_STATUS && tableStatus === SUCCEEDED_STATUS ?
        <>
          <div className="table__copy-table__wrapper" />
          <iframe className="table__print-iframe"
                  title="print-table"
                  name="print-table"
                  width="0"
                  height="0"
                  frameBorder="0"
                  src="about:blank" />
          <DataTable columns={ tableOptions }
                     data={ filteredData }
                     onSort={ ( a, b, c ) => { setActionsData( c ) } }
                     defaultSortFieldId={ defaultSortFieldId }
                     defaultSortAsc={ defaultSort === 'asc' }
                     striped
                     pagination
                     paginationPerPage={ rowsPerPage }
                     paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                     onChangeRowsPerPage={ newPerPage => dispatch( { type: TABLE_CHANGE_ROWS_PER_PAGE, payload: newPerPage } ) }
                     paginationComponentOptions={ paginationComponentOptions }
                     actions={ actionsComponent }
                     noDataComponent={ noDataComponent }
                     subHeader
                     subHeaderWrap={ false }
                     subHeaderComponent={ subHeaderComponent } />
        </>
      : <TableSkeleton colCount={ columns } /> }
    </div>
  )
});

export default Table;