import { createSlice } from '@reduxjs/toolkit';
import { client }      from '../../../../api/client';

// import constants
import { API_URL }          from '../../../../constants/base';
import { SUCCEEDED_STATUS } from '../../../../constants/loadingStatuses';
import { LOGIN_LOGIN, LOGIN_FAILED, LOGIN_LOGOUT, LOGIN_CHANGE_SCREEN } from '../../../../constants/dispatchTypes';

// import utils
import {
  setAccessToken, setRefreshToken, getRefreshToken, setUser, logout, isUserRemembered, getAccessToken
} from '../../../../utils/Auth';
import {refreshTokenHelper} from "../../../../api/helper";

/**
 * Create request to get access token and log in user
 *
 * @param res | object
 */
export const processingLogin = res => {
  return async function loginThunk( dispatch ) {
    const { username, password, isRemembered } = res;
    const data = { username, password };

    const response = await client.post( API_URL + 'authentication/create', data );

    if ( response.status === 200 ) {
      const payload = {
        ...response.data,
        isRemembered
      };

      dispatch( { type: LOGIN_LOGIN, payload } );
    }

    if ( response.status === 401 ) {
      dispatch( { type: LOGIN_FAILED, payload: response.data } );
    }
  }
};

/**
 * Create request to remove access token and log out the user
 *
 * @param isRemembered | bool
 */
export const processingLogout = isRemembered => {
  return async function logoutThunk( dispatch ) {
    await refreshTokenHelper( 'update', isRemembered, dispatch );

    const response = await client.post(
      API_URL + 'authentication/logout',
      { refresh: getRefreshToken( isRemembered ) },
      { headers: { 'Authorization': "Bearer " + getAccessToken( isRemembered ) } }
      );

    if ( response.status === 200 ) {
      dispatch( { type: LOGIN_LOGOUT } );
    }

    if ( response.status === 401 ) {
      dispatch( { type: LOGIN_FAILED, payload: response.data } );
    }
  }
};

/**
 * Create request to remind the password
 */
// TODO: check and update this function after connection to the real API point
export const processingForgot = () => {
  return async function forgotThunk( dispatch ) {
    // TODO: change the request to POST
    await client.get( 'https://swapi.dev/api/people/1/' );

    dispatch( { type: LOGIN_CHANGE_SCREEN, payload: true } );
  }
};

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isLoginScreen: true,
    isRemembered: isUserRemembered(),
    status: SUCCEEDED_STATUS,
    errorMsg: 'Something went wrong! Please try again.',
    isError: false,
  },
  reducers: {
    changeStatus: ( state, action ) => {
      state.status = action.payload;
    },
    changeScreen: ( state, action ) => {
      state.isLoginScreen = action.payload;
      state.status        = SUCCEEDED_STATUS;
    },
    changeRemember: ( state, action ) => {
      state.isRemembered = action.payload;
    },
    logIn: ( state, action ) => {
      console.log( action.payload );
      const {
        access, refresh, isRemembered, username, language, currency, merchandise, package: userPackage, profile_picture,
        company_name
      } = action.payload;

      setAccessToken( access, isRemembered );
      setRefreshToken( refresh, isRemembered );
      setUser({
        userName: username,
        language,
        currency,
        merchandise,
        userPackage,
        avatar: profile_picture,
        company: company_name
      }, isRemembered );

      state.status       = SUCCEEDED_STATUS;
      state.isRemembered = isRemembered;
      state.isError      = false;
    },
    logOut: ( state, action ) => {
      logout();

      state.status       = SUCCEEDED_STATUS;
      state.isRemembered = false;
      state.isError      = false;
    },
    loginFailed: ( state, action ) => {
      state.errorMsg = action.payload.detail;
      state.status   = SUCCEEDED_STATUS;
      state.isError  = true;
    },
    updateTokens: ( state, action ) => {
      const { access, refresh, isRemembered } = action.payload;

      setAccessToken( access, isRemembered );
      setRefreshToken( refresh, isRemembered );
    }
  }
});

export const { changeScreen, changeStatus, changeRemember } = loginSlice.actions;

export default loginSlice.reducer;