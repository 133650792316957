import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client }                        from '../../../api/client';

// import constants
import { API_URL } from '../../../constants/base';
import { IDLE_STATUS, LOADING_STATUS, SUCCEEDED_STATUS, FAILED_STATUS } from '../../../constants/loadingStatuses';
import { LANDING_FETCH, LANDING_DATE_UPDATE, TOAST_SHOW } from '../../../constants/dispatchTypes';

// import helpers
import { refreshTokenHelper, updateDateRequest } from '../../../api/helper';

// import utils
import { _t, setMetaTitle }                 from '../../../utils/i18n';
import { isUserRemembered, getAccessToken } from '../../../utils/Auth';

// define API urls
const defaultUrl = 'dashboard_stats_api/landing/';
const updateUrl  = 'dashboard_stats_api/update-landing/';

// define table options

/**
 * Make request to the server to get the initial data
 *
 * @return object
 */
export const fetchLanding = createAsyncThunk( LANDING_FETCH, async ( args, thunkAPI ) => {
  try {
    const { period, dateFrom, dateTo } = thunkAPI.getState().calendar.period;
    const isRemembered = isUserRemembered();

    await refreshTokenHelper( 'initial', isRemembered, thunkAPI );

    const headers = { 'Authorization': "Bearer " + getAccessToken( isRemembered ) };

    if ( period === '' || period === 'default' ) {
      const response = await client.get( API_URL + defaultUrl, { headers } );

      return response.data;
    } else {
      const defaultResponse = await client.get( API_URL + defaultUrl, { headers } );
      const requestResponse = await client.get(
        period === 'custom' ? API_URL + updateUrl + '?dateFrom=' + dateFrom + '&dateTo=' + dateTo : API_URL + updateUrl + '?period=' + period,
        { headers }
      );

      return {
        topStatsData          : [...requestResponse.data.topStatsData],
        latestDonationsData   : [...defaultResponse.data.latestDonationsData],
        productTypesAmountData: [...defaultResponse.data.productTypesAmountData],
        productsPerformingData: [...defaultResponse.data.productsPerformingData],
        transactionsChartData : [...requestResponse.data.transactionsChartData]
      };
    }
  } catch ( err ) {
    thunkAPI.dispatch( { type: TOAST_SHOW, payload: { isShown: true, type: 'danger', text: _t( 'fetch_error' ) } } );
    console.log( err );

    return Promise.reject( err );
  }
});

/**
 * Make request to update the data if the date range was changed
 *
 * @param dateRange | object
 * @param updatingData | object
 * @return function
 */
const updatingData = {
  defaultUrl,
  updateUrl,
  dispatchType: LANDING_DATE_UPDATE
};

export const updatingLanding = dateRange => updateDateRequest( dateRange, updatingData, false );

/**
 * Create Landing Slice
 */
const statisticsSlice = createSlice({
  name: 'landing',
  initialState: {
    status: IDLE_STATUS,
    metaTitle: '',
    topStatsData: [],
    latestDonationsData: [],
    productTypesAmountData: [],
    productsPerformingData: [],
    transactionsChartData: [],
    error: _t( 'fetch_error' )
  },
  reducers: {
    statusUpdated: ( state, action ) => {
      state.status = action.payload;
    },
    dateUpdated: ( state, action ) => {
      const { topStatsData, transactionsChartData } = action.payload;

      state.topStatsData          = topStatsData !== undefined ? topStatsData : state.topStatsData;
      state.transactionsChartData = transactionsChartData !== undefined ? transactionsChartData : state.transactionsChartData;
      state.status                = SUCCEEDED_STATUS;
    }
  },
  extraReducers( builder ) {
    builder
      .addCase( fetchLanding.pending, state => {
        state.status = LOADING_STATUS;
      })
      .addCase( fetchLanding.fulfilled, ( state, action ) => {
        const {
          topStatsData, transactionsChartData, latestDonationsData,
          productTypesAmountData, productsPerformingData, metas
        } = action.payload;

        state.metaTitle              = metas !== undefined ? setMetaTitle( metas ) : state.metaTitle;
        state.topStatsData           = topStatsData !== undefined ? topStatsData : state.topStatsData;
        state.transactionsChartData  = transactionsChartData !== undefined ? transactionsChartData : state.transactionsChartData;
        state.latestDonationsData    = latestDonationsData !== undefined ? latestDonationsData : state.latestDonationsData;
        state.productTypesAmountData = productTypesAmountData !== undefined ? productTypesAmountData : state.productTypesAmountData;
        state.productsPerformingData = productsPerformingData !== undefined ? productsPerformingData : state.productsPerformingData;
        state.status                 = SUCCEEDED_STATUS;
      })
      .addCase( fetchLanding.rejected, ( state, action ) => {
        state.status = FAILED_STATUS;
        state.error  = action.error.message;

        console.log( action.error.message );
      })
  },
});

export const { statusUpdated: landingStatusUpdating } = statisticsSlice.actions;

export default statisticsSlice.reducer;
