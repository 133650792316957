import React, { memo }              from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import actions
import { changeSection, collapseSidebar, changeActiveDropdown } from '../../common/Sidebar/store/sidebarSlice';

const SidebarToolsItem = memo( ({ children, type, text }) => {
  // get the global state
  const { activeSection, isCollapsed } = useSelector( state => state.sidebar );

  // define dispatch
  const dispatch = useDispatch();

  /**
   * Processing click
   */
  const handleClick = () => {
    const data = {
      activeSection: type,
      sectionTitle: text
    };

    dispatch( changeSection( data ) );

    if ( isCollapsed ) {
      dispatch( collapseSidebar( !isCollapsed ) );
    }

    if ( activeSection !== type ) {
      dispatch( changeActiveDropdown( '' ) );
    }
  };

  return (
    <div className={ activeSection === type ? 'sidebar-tools__item sidebar-tools__item--is-active' : 'sidebar-tools__item' }
         onClick={ () => handleClick() }>
      { children }
      <span className="sidebar-tools__item__tooltip">{ text }</span>
    </div>
  );
});

export default SidebarToolsItem;