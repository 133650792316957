// import helpers
import { swissNumberFormat } from '../../../helpers/swissNumberFormat';

// import utils
import { _t }                        from '../../../utils/i18n';
import { getUser, isUserRemembered } from '../../../utils/Auth';

/**
 * ApexChart options for the donut chart type
 *
 * @param data | object
 * @return object
 */
export function donutConfig( data ) {
  const getValues    = data => data?.map( item => item.amount );
  const isRemembered = isUserRemembered();
  const { currency } = getUser( isRemembered );
  const getLabels    = data => data?.map(
    item => {
      const { title } = item;

      return title === 'Other' ? _t( 'product_performing_other' ) : title;
    }
  );

  return {
    chartDimensions: 165,
    series: getValues( data ),
    labels: getLabels( data ),
    colors: ['#63059B', '#8A52C8', '#A576D7', '#C09DE7', '#D9BEF6'],
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        offsetX: -18,
        donut: {
          size: 80,
        }
      }
    },
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.01,
        }
      },
    },
    stroke: {
      width: 0
    },
    tooltip: {
      style: {
        fontSize: '12px',
        fontFamily: 'Montserrat, sans-serif',
      },
      y: {
        formatter: val => currency + ' ' + swissNumberFormat( val ),
      },
    }
  }
}