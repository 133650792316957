// import utils
import { _t } from '../utils/i18n';

export const TODAY_LABEL        = _t( 'calendar_today' );
export const YESTERDAY_LABEL    = _t( 'calendar_yesterday' );
export const LAST_7_DAYS_LABEL  = _t( 'calendar_last_7_days' );
export const LAST_30_DAYS_LABEL = _t( 'calendar_last_30_days' );
export const THIS_MONTH_LABEL   = _t( 'calendar_this_month' );
export const LAST_MONTH_LABEL   = _t( 'calendar_last_month' );
export const THIS_QUARTER_LABEL = _t( 'calendar_this_quarter' );
export const LAST_QUARTER_LABEL = _t( 'calendar_last_quarter' );
export const THIS_YEAR_LABEL    = _t( 'calendar_this_year' );
export const LAST_YEAR_LABEL    = _t( 'calendar_last_year' );

export const PERIOD_30_DAYS                     = '30_days';
export const PERIOD_YESTERDAY                   = 'yesterday';
export const PERIOD_DAY_BEFORE_YESTERDAY        = 'day_before_yesterday';
export const PERIOD_LAST_WEEK                   = 'last_week';
export const PERIOD_LAST_MONTH                  = 'last_month';
export const PERIOD_MONTH_BEFORE_THIS_MONTH     = 'month_before_this_month';
export const PERIOD_LAST_QUARTER                = 'last_quartal';
export const PERIOD_QUARTER_BEFORE_LAST_QUARTER = 'quartal_before_last_quartal';
export const PERIOD_LAST_YEAR                   = 'last_year';
export const PERIOD_YEAR_BEFORE_LAST_YEAR       = 'year_before_last_year';

export const CALENDAR_TODAY        = 'today';
export const CALENDAR_YESTERDAY    = 'yesterday';
export const CALENDAR_LAST_7_DAYS  = 'last-7days';
export const CALENDAR_LAST_30_DAYS = 'default';
export const CALENDAR_THIS_MONTH   = 'this-month';
export const CALENDAR_LAST_MONTH   = 'last-month';
export const CALENDAR_THIS_QUARTER = 'this-quartal';
export const CALENDAR_LAST_QUARTER = 'last-quartal';
export const CALENDAR_THIS_YEAR    = 'this-year';
export const CALENDAR_LAST_YEAR    = 'last-year';