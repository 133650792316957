import React, { useEffect }         from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink }                  from 'react-router-dom';
import { Helmet }                   from 'react-helmet';

// import constants
import { ROOT_PATH } from '../../../constants/paths';

// import actions
import { changeSection } from '../../common/Sidebar/store/sidebarSlice';

// import utils
import { _t } from '../../../utils/i18n';

// import icons
import { IconAlertTriangle } from '@tabler/icons-react';

// import images
import image404 from '../../../assets/images/404-image.svg';

// import components
import Footer from '../Footer/Footer';

export default function Error404() {
  // get the global state
  const { isCollapsed } = useSelector( state => state.sidebar );

  // define dispatch
  const dispatch = useDispatch();

  // hide sidebar section
  useEffect( () => {
      const data = {
        activeSection: 'empty',
        sectionTitle: ''
      };

      dispatch( changeSection( data ) );
  }, []);

  // make an active section if click on the back to the home
  const handleClick = () => {
    const data = {
      activeSection: 'dashboard',
      sectionTitle: _t( 'menu_title_dashboard' )
    };

    dispatch( changeSection( data ) );
  };

  return(
    <section className={ isCollapsed ? "main main--is-extended" : "main" }>
      <div className="container error-container">
        <Helmet>
          <title>{ _t( 'error_meta_title' ) }</title>
        </Helmet>
          <div className="error">
            <IconAlertTriangle className="error__icon"
                               size={ 128 }
                               color="#444441" />
            <h1 className="error__title">{ _t( 'error_title' ) }</h1>
            <img className="error__image"
                 src={ image404 }
                 alt={ _t( 'error__title' ) } />
            <NavLink className="btn btn--regular btn--type-1"
                     to={ ROOT_PATH }
                     onClick={ () => handleClick() }>
              { _t( 'error_btn_text' ) }
            </NavLink>
          </div>
      </div>
      <Footer />
    </section>
  )
}