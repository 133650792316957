import React, { memo } from 'react';

// import constants
import { ALL_PAYMENTS_LOCATION } from '../../../constants/componentLocation';

// import components
import ModuleWrapperButton from './ModuleWrapperButton';

const ModuleWrapper = memo( ( { children, title, description, button } ) => {
  return (
    <div className="module">
      <h2 className="module__title">
        { title }
        { description ? <span className="module__description">{ description }</span> : false }
        { button ? <ModuleWrapperButton buttonText={ button } action={ ALL_PAYMENTS_LOCATION } /> : false }
      </h2>
      { children }
    </div>
  )
});

export default ModuleWrapper;