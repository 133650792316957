import React                        from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate }              from 'react-router-dom';

// import constants
import { LOGIN_PATH } from '../../../constants/paths';

// import utils
import { isLoggedIn } from '../../../utils/Auth';

// import actions
import { processingLogout } from '../../common/Login/store/loginSlice';

// import icons
import { IconLogout } from '@tabler/icons-react';

export default function SidebarNavLogout() {
  // get the global state
  const { isRemembered, theme } = useSelector( state => {
    const {
      sidebar: { theme },
      login: { isRemembered }
    } = state;

    return { isRemembered, theme };
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * Processing click on the logout button
   */
  const handleClick = () => {
    dispatch( processingLogout( isRemembered ) )
      .then( () => {
        if ( !isLoggedIn( isRemembered ) ) {

          // TODO: not a proper way but it works
          navigate( LOGIN_PATH );
          window.location.reload();
        }
      });
  };

  return (
    <div className="sidebar-nav__logout">
      <IconLogout className="sidebar-nav__logout__icon"
                  color={ theme === 'dark' ? '#fff' : '#444441' }
                  onClick={ () => handleClick() } />
    </div>
  )
}