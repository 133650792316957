import React, { memo } from 'react';

// import components
import Badge from '../Badge/Badge';

const TableStatus = memo( ({ status, text }) => {
  return (
    <Badge type={ status ? 'success' : 'failed' } text={ text } />
  );
});

export default TableStatus;