import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

// import constants
import { LOADING_STATUS }        from '../../../constants/loadingStatuses';
import { ALL_PAYMENTS_LOCATION } from '../../../constants/componentLocation';

// import actions
import { getAllTimeForAllPayments, allPaymentsTableStatusUpdating } from '../../../pages/AllPayments/api/allPaymentsSlice';
import { changeAllTimeAllPayments } from '../../../components/elements/Calendar/store/calendarSlice';

const ModuleWrapperButton = memo( ( { buttonText, action } ) => {
  // define dispatch
  const dispatch = useDispatch();

  /**
   * Processing click on the Module button
   */
  const handleClick = ( action ) => {
    switch ( action ) {
      case ALL_PAYMENTS_LOCATION:
        dispatch( getAllTimeForAllPayments() );
        dispatch( allPaymentsTableStatusUpdating( LOADING_STATUS ) );
        dispatch( changeAllTimeAllPayments( true ) );
        break;
      default:
        console.log( 'default' );
        break;
    }
  };

  return (
    <button className="module__btn badge badge--primary"
            type="button"
            onClick={ () => handleClick( action ) }>{ buttonText }</button>
  )
});

export default ModuleWrapperButton;