import React, { useState }          from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate }              from 'react-router-dom';
import { Helmet }                   from 'react-helmet';

// import constants
import { ROOT_PATH }      from '../../../constants/paths';
import { LOADING_STATUS } from '../../../constants/loadingStatuses';

// import actions
import { changeStatus/*, changeScreen*/, processingLogin } from './store/loginSlice';
import { showToast } from '../../elements/Toast/store/toastSlice';

// import utils
import { isLoggedIn }       from '../../../utils/Auth';
import { _t_byBrowserLang } from '../../../utils/i18n';

// import components
import LoginRemember   from './LoginRemember';
import ButtonPreloader from '../../elements/ButtonPreloader/ButtonPreloader';

export default function LoginForm() {
  // define dispatch
  const dispatch = useDispatch();

  // define navigate
  const navigate = useNavigate();

  // define the global state
  const { status, errorMsg, isError, isRemembered } = useSelector( state => state.login );

  // define the component state
  const [username, setUsername]           = useState( '' );
  const [password, setPassword]           = useState( '' );
  const [isUsernameErr, setIsUsernameErr] = useState( false );
  const [isPassErr, setIsPassErr]         = useState( false );

  /**
   * Processing the submitting function
   *
   * @param e | Event
   */
  const handleSubmit = e => {
    e.preventDefault();

    if ( username === '' || password === '' ) {
      // add an error state to the username field
      if ( username === '' ) setIsUsernameErr( true );

      // add an error state to the password field
      if ( password === '' ) setIsPassErr( true );
    } else {
      dispatch( changeStatus( LOADING_STATUS ) );
      dispatch( processingLogin( { username, password, isRemembered } ) )
        .then( () => {
          if ( isLoggedIn( isRemembered ) ) {

            // TODO: not a proper way but it works
            navigate( ROOT_PATH );
            window.location.reload();
          }
        })
        .catch( res => {
          dispatch( showToast( { isShown: true, type: 'danger', text: _t_byBrowserLang( 'fetch_error' ) } ) );
          
          console.log( 'response: ', res );
        });
    }
  };

  return (
    <div className="login__area--form">
      <Helmet>
        <title>{ _t_byBrowserLang( 'login_meta_title' ) }</title>
      </Helmet>
      <h1 className="login__title">{ _t_byBrowserLang( 'login_title' ) }</h1>
      <p className="login__description">{ _t_byBrowserLang( 'login_subtitle' ) }</p>
      <form className="login__form"
            onSubmit={ ( e ) => handleSubmit( e ) }>
        <div className="login__form__field-group">
          <input className={ isUsernameErr ? 'login__form__field login__form__field--is-error' : 'login__form__field' }
                 id="login-username"
                 type="text"
                 onFocus={ () => setIsUsernameErr( false ) }
                 onChange={ ( e ) => setUsername( e.target.value ) }
                 placeholder=" "/>
          <label className="login__form__label"
                 htmlFor="login-username">{ _t_byBrowserLang( 'login_username_label' ) }</label>
        </div>

        <div className="login__form__field-group">
          <input className={ isPassErr ? 'login__form__field login__form__field--is-error' : 'login__form__field' }
                 id="login-password"
                 type="password"
                 onFocus={ () => setIsPassErr( false ) }
                 onChange={ ( e ) => setPassword( e.target.value ) }
                 placeholder=" " />
          <label className="login__form__label"
                 htmlFor="login-password">{ _t_byBrowserLang( 'login_password_label' ) }</label>
        </div>

        <div className="login__form__actions">
          <LoginRemember />
          {/*<span className="login__form__forgot"
                onClick={ () => dispatch( changeScreen( false ) ) }>{ _t_byBrowserLang( 'login_forgot_label' ) }</span>*/}
        </div>

        <button className={ status === LOADING_STATUS ? 'login__form__submit login__form__submit--is-loading' : 'login__form__submit' }
                type="submit"
                disabled={ status === LOADING_STATUS }>
          { status === LOADING_STATUS ? <ButtonPreloader /> : _t_byBrowserLang( 'login_button_text' ) }
        </button>

        { isError ? <p className="login__form__error">{ errorMsg }</p> : false }
      </form>
    </div>
  )
}