import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch }         from 'react-redux';
import { NavLink }                          from 'react-router-dom';

// import utils
import { _t } from '../../../utils/i18n';

// import actions
import { changeActiveDropdown } from '../../common/Sidebar/store/sidebarSlice';

// import icons
import { IconChevronDown } from '@tabler/icons-react';

const SidebarNavItemWithChild = memo( ({ data: { title, children, link, iconColor, isSingleClicked, isDisabled }, flushActive }) => {
  // get the global state
  const { activeDropdown } = useSelector( state => state.sidebar );

  // define the component state
  const [isChildOpened, setChildOpened] = useState( false );
  const [isHighlighted, setHighlighted] = useState( false );

  // define dispatch
  const dispatch = useDispatch();

  /**
   * Change state if a single menu item was clicked
   */
  useEffect( () => {
    if ( activeDropdown === link ) {
      setChildOpened( true );
    } else {
      setChildOpened( false );
      setHighlighted( false );
    }
  }, [activeDropdown, link] );

  /**
   * Processing click if submenu is opened
   */
  const handleClickSubList = () => {
    setChildOpened( !isChildOpened );
    flushActive( false );
  };

  /**
   * Processing click if a sub menu item was clicked
   */
  const handleClickSubItem = () => {
    setHighlighted( true );
    flushActive( false );

    dispatch( changeActiveDropdown( link ) );
  };

  const disabledClassName = isDisabled ? ' sidebar-nav__item--is-disabled' : '';

  return (
    <div className="sidebar-nav__item-with-child">
      <span className={ isHighlighted ? 'sidebar-nav__item sidebar-nav__item--has-child sidebar-nav__item--is-active' + disabledClassName : 'sidebar-nav__item sidebar-nav__item--has-child' + disabledClassName }
            onClick={ () => handleClickSubList() }>
        { title }
        <IconChevronDown className={ isChildOpened ? 'sidebar-nav__item__icon sidebar-nav__item__icon--is-active' : 'sidebar-nav__item__icon' }
                         color={ iconColor } />
      </span>
      <div className={ isChildOpened ? 'sidebar-nav__sub-list sidebar-nav__sub-list--is-opened' : 'sidebar-nav__sub-list' }>
        { children.map( childItem => {
          const { title, link, isDisabled } = childItem;

          if ( isDisabled ) {
            return <span className="sidebar-nav__sub-item sidebar-nav__sub-item--is-disabled"
                         key={ link }
                         title={ _t( 'menu_disabled_msg' ) }>{ title }</span>
          } else {
            return <NavLink className={ ( { isActive } ) => isActive ? 'sidebar-nav__sub-item sidebar-nav__sub-item--is-active' : 'sidebar-nav__sub-item' }
                            to={ link }
                            key={ link }
                            onClick={ () => handleClickSubItem() }>{ title }</NavLink>
          }
        })}
      </div>
    </div>
  );
});

export default SidebarNavItemWithChild;