import React, { memo } from 'react';

const ButtonPreloader = memo( () => {
  return (
    <div className="button-preloader">
      <div className="button-preloader__item button-preloader__item--1" />
      <div className="button-preloader__item button-preloader__item--2" />
      <div className="button-preloader__item button-preloader__item--3" />
    </div>
  )
});

export default ButtonPreloader;