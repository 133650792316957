import React, { memo } from 'react';
import { Helmet }      from 'react-helmet';

// import utils
import { getUser, isUserRemembered } from '../../../utils/Auth';

// import components
import Toast         from '../../elements/Toast/Toast';
import PopupResend   from '../../elements/Popup/PopupResend';
import TopStats      from '../../elements/TopStats/TopStats';
import PageTitle     from '../../elements/PageTitle/PageTitle';
import Calendar      from '../../elements/Calendar/Calendar';
import TopProducts   from '../../elements/TopProducts/TopProducts';
import Table         from '../../elements/Table/Table';
import ModuleWrapper from '../../common/ModuleWrapper/ModuleWrapper';

const ContainerWrapper = memo( (
  {
    children, metaTitle, location, popupResend, topStats, pageTitle, pageTitleText, pageDescriptionText, topProducts, topProductsTitle,
    topProductsDescription, table, tableTitle, tableDescription, tableColumns, tableExportFields, tableHasButton,
    tableHasCalendar, tableButtonText, tableModuleDescription, childrenPosition = 'bottom'
  } ) => {
  // get the user currency
  const { currency } = getUser( isUserRemembered() );

  return (
    <div className="container main-container">
      <Helmet>
        <title>{ metaTitle }</title>
      </Helmet>

      <Toast />

      { popupResend ? <PopupResend /> : false }

      { topStats ?
        <div className="row">
          <div className="col col-xs-12">
            <TopStats location={ location } />
          </div>
        </div>
      : false }

      { pageTitle ?
        <div className="row">
          <div className="col col-xs-12">
            <PageTitle title={ pageTitleText } description={ pageDescriptionText } />
          </div>
        </div>
      : false }

      { childrenPosition === 'top' ? children : false }

      { topProducts ?
        <div className="row">
          <div className="col col-xs-12">
            <ModuleWrapper title={ topProductsTitle }
                           description={ topProductsDescription === 'currency' ? `in ${ currency }` : topProductsDescription }
                           withCalendar={ true }>
              <Calendar location={ location } />
              <TopProducts location={ location } />
            </ModuleWrapper>
          </div>
        </div>
      : false }

      { childrenPosition === 'middle' ? children : false }

      { table ?
        <div className="row">
          <div className="col col-xs-12">
            <ModuleWrapper title={ tableTitle }
                           description={ tableDescription }
                           {...( tableHasButton ? { button: tableButtonText } : false )}>
              { tableModuleDescription ? <p className="module__text">{ tableModuleDescription }</p> : false }
              { tableHasCalendar ? <Calendar location={ location } /> : false }
              <Table location={ location }
                     defaultSortFieldId="date"
                     defaultSort="desc"
                     columns={ tableColumns }
                     exportFields={ tableExportFields } />
            </ModuleWrapper>
          </div>
        </div>
      : false }

      { childrenPosition === 'bottom' ? children : false }
    </div>
  );
});

export default ContainerWrapper;