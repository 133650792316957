// import languages
import { de } from './languages/de';
import { en } from './languages/en';

// import utils
import { getUser, isUserRemembered } from '../Auth';

/**
 * Translation function
 *
 * @param keyword | string
 * @return string
 */
export const _t = keyword => {
  const isRemembered = isUserRemembered();
  const { language } = getUser( isRemembered );

  switch ( language ) {
    case 'de':
      return de?.[keyword];
    default:
      return en?.[keyword];
  }
};

/**
 * Translation function which used the browser lang as default
 *
 * @param keyword | string
 * @return string
 */
export const _t_byBrowserLang = keyword => {
  const browserLang = navigator.language;

  if ( /^de\b/.test( browserLang ) ) {
    return de?.[keyword];
  } else {
    return en?.[keyword];
  }
};

/**
 * Moment js locale
 *
 * @return string
 */
export const momentLocale = () => {
  const isRemembered = isUserRemembered();
  const { language } = getUser( isRemembered );

  switch ( language ) {
    case 'de':
      return 'de-ch';
    default:
      return 'en-in';
  }
};

/**
 * Check the current browser language
 *
 * @return string
 */
export const checkBrowserLang = () => {
  const browserLang = navigator.language;

  if ( /^de\b/.test( browserLang ) ) {
    return 'de';
  } else {
    return 'en';
  }
};

/**
 * Set meta title by the current language
 *
 * @param meta | object
 * @return string
 */
export const setMetaTitle = meta => {
  const isRemembered = isUserRemembered();
  const { language } = getUser( isRemembered );

  switch ( language ) {
    case 'de':
      return meta.site_title_de;
    default:
      return meta.site_title_en;
  }
};